import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, FormControl, InputLabel, MenuItem, Select, Autocomplete, TableBody, TableRow, TableCell, Checkbox, Tooltip, Grid, Paper, Toolbar, IconButton, Typography, alpha } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import Loading from '../../components/Loading';
import { Category, CategoryForm, CategoryModel, CategoryType } from '../../types/Entites';
import { getCategories, getCategory, getListCategoryWithParent, putCategory } from '../../utils/api';
import { getComparator, Order, stableSort } from '../../components/TableHelper';
import { Add, Delete, Edit } from '@mui/icons-material';
import DataTable, { EnhancedTableToolbarProps } from '../../components/DataTable';
import { CategoryTableHeadCells } from '../../utils/TableHeadCells';
import { ServiceResponse } from '../../utils/ServiceResponse';

export default function EditCategory() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [categoryForm, setCategoryForm] = useState({ name: '', description: '', categoryType: CategoryType.Default } as CategoryForm);
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Category>('id');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [serviceResponse, setServiceResponse] = useState<ServiceResponse<CategoryModel>>({} as ServiceResponse<CategoryModel>)

    //#region 
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - serviceResponse.list.length) : 0;
    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const { numSelected } = props;
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {props.tableName}
                    </Typography>
                )}
                {numSelected == 0 && <IconButton onClick={props.goAddPage}>
                    <Add />
                </IconButton>}
                {numSelected == 1 &&
                    <Tooltip title="Düzenle">
                        <IconButton onClick={props.goEditPage}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }
                {
                    numSelected > 0 &&
                    <Tooltip title="Sil">
                        <IconButton onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar >
        );
    };
    useEffect(() => {
        loadCategory();
        loadCategories();
        setLoading(false);
        return () => {
            setLoading(true);
            setCategoryForm({ name: '', description: '', categoryType: CategoryType.Default } as CategoryForm);
            setSelected([]);
        }
    }, [navigate]);
    const loadCategory = async () => {
        await getListCategoryWithParent(id as any).then((res) => {
            setServiceResponse(res.data);
            setCategoryForm(res.data.entity as any);

        })
            .catch((err) => { });
    }
    const loadCategories = async () => {
        await getCategories().then((res) => {
            setCategories(res.data.list as any);
        }).catch((err) => { });
    }
    const updateCategory = async () => {
        await putCategory(categoryForm).then((res) => {
            navigate(-1);
        }).catch((err) => { });
    }
    return (
        <Loading loading={loading}>
            <Box sx={{ mt: 1 }}>
                <TextField
                    value={categoryForm.name}
                    onChange={(e) => setCategoryForm({ ...categoryForm, name: e.target.value })}
                    margin="dense"
                    required
                    fullWidth
                    id="name"
                    label="Category Name"
                    name="name"
                    autoFocus
                />
                <TextField
                    value={categoryForm.description}
                    onChange={(e) => setCategoryForm({ ...categoryForm, description: e.target.value })}
                    margin="dense"
                    required
                    fullWidth
                    id="description"
                    label="Category Description"
                    name="description"
                />
                <FormControl margin='dense' fullWidth>
                    <InputLabel id="demo-simple-select-label">Category Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={categoryForm.categoryType as CategoryType}
                        label="Category Type"
                        onChange={(e) => {
                            setCategoryForm({ ...categoryForm, categoryType: e.target.value as CategoryType })
                        }}
                    >
                        <MenuItem value={CategoryType.Default}>Root</MenuItem>
                        <MenuItem value={CategoryType.MainCategory}>Main Category</MenuItem>
                        <MenuItem value={CategoryType.SubCategory}>Sub Category</MenuItem>
                    </Select>
                </FormControl>
                {
                    categoryForm.categoryType !== CategoryType.Default &&
                    <FormControl margin='dense' fullWidth>
                        <InputLabel id="demo-simple-select-label">Categories</InputLabel>
                        <Select
                            value={categoryForm.parent}
                            onChange={(e) => {

                                setCategoryForm({ ...categoryForm, parent: [e.target.value as any] });
                            }}
                            labelId="demo-simple-select-label"
                            label="Categories"
                            id="categories-select"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>}
                <Button
                    onClick={updateCategory}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>
            </Box>
            <Grid container sx={{ padding: "10px" }}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        {serviceResponse.entity !== undefined && serviceResponse.entity.categories !== undefined && <DataTable
                            EnhancedTableToolbar={EnhancedTableToolbar}
                            rows={serviceResponse.entity.categories.map((item) => item.id.toString())}
                            HeadCell={CategoryTableHeadCells}
                            order={order}
                            setOrder={(data) => setOrder(data)}
                            orderBy={orderBy}
                            setOrderBy={(data) => setOrderBy(data)}
                            selected={selected}
                            setSelected={(data) => setSelected(data)}
                            page={page}
                            setPage={(page) => setPage(page)}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={(data) => setRowsPerPage(data)}
                            goAddPage={() => {
                                navigate('/category/add');
                            }}
                            goEditPage={() => {
                                navigate('/category/' + selected[0]);
                            }}
                            handleDelete={() => {

                            }}
                            tableName="Categories"
                            tableBody={serviceResponse.entity.categories !== undefined && serviceResponse.entity.categories.length !== 0 &&
                                <TableBody>
                                    {stableSort(serviceResponse.entity.categories, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id.toString());
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id.toString())}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{row.image}</TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.description}</TableCell>
                                                    <TableCell>{row.parent}</TableCell>
                                                    <TableCell>{row.status === 1 ? "Active" : "Passive"}</TableCell>
                                                    <TableCell>{
                                                        row.categoryType === CategoryType.Default ? "Root Category" : CategoryType.SubCategory ? "Sub Category" : "Main Category"
                                                    }</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            }
                        />}
                    </Paper>
                </Grid>
            </Grid>
        </Loading>
    )
}
