import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../types/Entites";

const INITIAL_STATE = {
    products: [] as Array<Product>,
    selectedProducts: [] as Array<Product>,
}
const productSlice = createSlice({
    name: 'product',
    initialState: INITIAL_STATE,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setProduct: (state, action) => {
            state.products = [...state.products, action.payload];
        },
        setSelectedProducts: (state, action) => {
            state.selectedProducts = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProducts = [...state.selectedProducts, action.payload];
        }
    }
});
export const productReducers = productSlice.reducer;
export const {
    setProducts,
    setProduct,
    setSelectedProducts,
    setSelectedProduct
} = productSlice.actions;
