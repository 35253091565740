import axios from "axios";
import { Admin, Brands, Category, CategoryForm, CategoryModel, Customer, GroupedProduct, LoginModel, OrderBasket, OrderDTO, OrderVM, Product, ProductVariant, ProductVM, Variant, VariantItem } from "../types/Entites";
import { ServiceResponse } from "./ServiceResponse";
// export const baseUrl = 'http://192.168.1.104:7067';
export const baseUrl = 'https://ecommerce.kivi-soft.com';
export default function api() {
    var user = JSON.parse(localStorage.getItem('user') as any) as any;
    if (user == null) {
        return axios.create({
            baseURL: baseUrl,
        })
    }
    else {
        return axios.create({
            baseURL: baseUrl,
            headers: {
                'Authorization': `bearer ${user.token.toString()}`
            }
        })
    }
}
export const postAdminLogin = async (email: string, password: string) => {
    return await api().post<ServiceResponse<LoginModel<Admin>>>(`/loginAdmin/${email}/${password}`);
}
export const getCategories = async () => {
    return await api().get<ServiceResponse<Category>>(`/getCategories`);
}
export const postVariant = async (variant: Variant) => {
    return await api().post<ServiceResponse<Variant>>(`/addVariant?Name=${variant.name}&Description=${variant.description}`, variant, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const getVariants = async () => {
    return await api().get<ServiceResponse<Variant>>(`/getListVariant`);
}
export const getVariant = async (id: number) => {
    return await api().get<ServiceResponse<Variant>>(`/getVariant/${id}`);
}
export const putVariant = async (entity: Variant) => {
    return await api().put<ServiceResponse<Variant>>(`/updateVariant`, entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const postVariantItem = async (variantItem: VariantItem) => {
    return await api().post<ServiceResponse<Variant>>(`/addVariantItem`, variantItem, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const putVariantItem = async (variantItem: VariantItem) => {
    return await api().put<ServiceResponse<Variant>>(`/updateVariantItem`, variantItem, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const getCategory = async (id: number) => {
    return await api().get<ServiceResponse<Category>>(`/getCategory/${id}`);
}

export const getListCategoryWithParent = async (id: number) => {
    return await api().get<ServiceResponse<CategoryModel>>(`/getListCategoryWithParent/${id}`);
}
export const getListCategoriesWithParent = async () => {
    return await api().get<ServiceResponse<CategoryModel>>(`/getListCategoriesWithParent`);
}
export const putCategory = async (category: CategoryForm) => {
    return await api().put<ServiceResponse<Category>>(`/updateCategory`, category, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const postCategory = async (category: CategoryForm) => {
    var formData = new FormData();
    formData.append("category[Name]", category.name);
    formData.append("category[Description]", category.description);
    formData.append("category[Image]", "");
    formData.append("category[categoryType]", category.categoryType as any);
    formData.append("category[parent]", category.parent as any);
    formData.append("category[status]", category.status as any);
    return await api().post<ServiceResponse<CategoryForm>>(`/addCategory`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const postBrand = async (brand: Brands) => {
    var formData = new FormData();
    formData.append("brand[name]", brand.name);
    formData.append("brand[description]", brand.description);
    formData.append("brand[brand_Link]", brand.brand_Link);
    formData.append("brand[brandImage]", "");
    formData.append("brand[status]", brand.status as any);
    return await api().post<ServiceResponse<Brands>>(`/addBrand`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const putBrand = async (brand: Brands) => {
    var formData = new FormData();
    formData.append("brand[id]", brand.id as any);
    formData.append("brand[name]", brand.name);
    formData.append("brand[description]", brand.description);
    formData.append("brand[brand_Link]", brand.brand_Link);
    formData.append("brand[brandImage]", "");
    formData.append("brand[status]", brand.status as any);
    return await api().put<ServiceResponse<Brands>>(`/updateBrand`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const getBrands = async () => {
    return await api().get<ServiceResponse<Brands>>("/getBrands");
}
export const getBrand = async (id: number) => {
    return await api().get<ServiceResponse<Brands>>(`/getBrand/${id}`);
}
export const postProduct = async (product: Product, imageFiles?: any) => {
    var formData = new FormData();
    if (imageFiles !== null) {
        [...imageFiles].map((item: File) => {
            formData.append("formFiles", item);
        });
    }
    formData.append("product[name]", product.name);
    formData.append("product[description]", product.description);
    formData.append("product[shortDescription]", product.shortDescription);
    formData.append("product[price]", product.price as any);
    formData.append("product[productType]", product.productType as any);
    formData.append("product[discount_Rate]", product.discount_Rate as any);
    formData.append("product[stock_Quantity]", product.stock_Quantity as any);
    formData.append("product[sales_Quantity]", product.sales_Quantity as any);
    formData.append("product[productStatus]", product.productStatus as any);
    formData.append("product[categoryID]", product.categoryID as any);
    formData.append("product[brandID]", product.brandID as any);
    return await api().post<ServiceResponse<Brands>>("/addProduct", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const getProducts = async () => {
    return await api().get<ServiceResponse<ProductVM>>("/products");
}
export const getProduct = async (id: number) => {
    return await api().get<ServiceResponse<ProductVM>>(`/getProduct/${id}`);
}
export const putProduct = async (product: Product) => {
    var formData = new FormData();
    formData.append("product[id]", product.id as any);
    formData.append("product[name]", product.name);
    formData.append("product[description]", product.description);
    formData.append("product[shortDescription]", product.shortDescription);
    formData.append("product[productType]", product.productType as any);
    formData.append("product[price]", product.price as any);
    formData.append("product[discount_Rate]", product.discount_Rate as any);
    formData.append("product[stock_Quantity]", product.stock_Quantity as any);
    formData.append("product[sales_Quantity]", product.sales_Quantity as any);
    formData.append("product[productStatus]", product.productStatus as any);
    formData.append("product[categoryID]", product.categoryID as any);
    formData.append("product[brandID]", product.brandID as any);
    return await api().put<ServiceResponse<Brands>>("/updateProduct", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const getOrders = async (pageNo: number, perPage: number) => {
    return await api().get<ServiceResponse<OrderVM>>(`/getOrders/${pageNo}/${perPage}`);
}
export const getOrder = async (id: number) => {
    return await api().get<ServiceResponse<OrderVM>>(`/getOrder/${id}`);
}
export const getSearchProduct = (searchText: string) => {
    return api().get<ServiceResponse<Product>>("/products/" + searchText);
}
export const addGroupedProduct = async (entity: Array<GroupedProduct>) => {
    return await api().post<ServiceResponse<GroupedProduct>>("/addGroupedProducts", entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const putGroupedProduct = async (entity: Array<GroupedProduct>, productId: number) => {
    return await api().put<ServiceResponse<GroupedProduct>>("/updateGroupedProducts/" + productId, entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const addProductVariant = async (entity: Array<ProductVariant>) => {
    return await api().post<ServiceResponse<ProductVariant>>("/addProductVariants", entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const updateProductVariant = async (entity: Array<ProductVariant>) => {
    return await api().put<ServiceResponse<ProductVariant>>("/updateProductVariants", entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const addOrder = async (entity: OrderDTO) => {
    return await api().post<ServiceResponse<OrderVM>>("/addOrder", entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const deleteAllProductVariants = async (productID: number, entity: Array<VariantItem>) => {
    return await api().delete<ServiceResponse<ProductVariant>>(`/deleteAllProductVariants/${productID}`, {
        data: entity,
    });
}
export const deleteAllGroupedProducts = async (productID: number, entity: Array<GroupedProduct>) => {
    return await api().delete<ServiceResponse<GroupedProduct>>(`/deleteAllGroupedProducts/${productID}`, {
        data: entity,
    });
}
export const updateOrderStatus = async (orderId: number) => {
    return await api().put<ServiceResponse<OrderVM>>(`/updateOrderStatus/${orderId}`);
}
export const deleteOrderBasket = async (orderId: number) => {
    return await api().delete<ServiceResponse<OrderVM>>(`/deleteOrderBasket/${orderId}`);
}
export const deleteOrders = async (orderIds: Array<number>) => {
    return await api().delete<ServiceResponse<OrderVM>>("/deleteOrders", {
        data: orderIds,
    });
}
export const addProductToCreatedBasket = async (list: Array<OrderBasket>, orderId: number) => {
    return await api().post<ServiceResponse<OrderVM>>("/addProductToCreatedBasket/" + orderId, list, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const updateBasketItemQuantity = async (basketItemId: number, quantity: number) => {
    return await api().put<ServiceResponse<OrderBasket>>(`/updateBasketItemQuantity/${basketItemId}/${quantity}`);
}
export const cancelOrder = async (orderId: number) => {
    return await api().post<ServiceResponse<OrderVM>>(`/cancelOrder/${orderId}`);
}
export const updateProductCoverImage = async (imageId: number) => {
    return await api().put<ServiceResponse<ProductVM>>(`/updateProductCoverImage/${imageId}`);
}
export const deleteProductImage = async (imageId: number) => {
    return await api().delete<ServiceResponse<ProductVM>>(`/deleteProductImage/${imageId}`);
}
export const addProductImage = async (imageFiles: any, productId: number) => {
    var formData = new FormData();
    [...imageFiles].map((item: File) => {
        formData.append("forms", item);
    });
    return await api().post<ServiceResponse<ProductVM>>("/addProductImage/" + productId, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const postCustomer = async (entity: Customer) => {
    return await api().post<ServiceResponse<Customer>>("/addCustomer", entity, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
export const postCustomerLogin = async (email: string, password: string) => {
    return await api().post<ServiceResponse<Customer>>(`/loginCustomer/${email}/${password}`, {}, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}