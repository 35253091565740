import { createSlice } from "@reduxjs/toolkit"
import { BasketVariant, BasketVM } from "../../types/Entites"

const INITIALSTATE = {
    basket: [] as BasketVM[],
    total: 0,
    selectedBasketVariant: [] as Array<BasketVariant>

}
const basketSlice = createSlice({
    name: 'basket',
    initialState: INITIALSTATE,
    reducers: {
        setToBasket: (state, action) => {
            const index = state.basket.findIndex(item => item.id === action.payload.id);
            if (index >= 0) {
                var entity = action.payload as BasketVM;
                if (entity.OrderVariantVMs.length !== 0) {
                    var findProduct = state.basket.find(x => x.product.id === entity.product.id);
                    if (findProduct) {
                        var checkVariant = false;
                        if (entity.OrderVariantVMs.length === findProduct.OrderVariantVMs.length) {
                            entity.OrderVariantVMs.filter((item) => {
                                if (findProduct != undefined) {
                                    var findVariant = findProduct.OrderVariantVMs.find(x => x.id === item.id);
                                    if (findVariant) {
                                        checkVariant = true;
                                        return;
                                    }
                                }
                                else {
                                    checkVariant = true;
                                    return;
                                }
                            })
                            if (checkVariant) {
                                state.basket = [...state.basket, action.payload];
                                state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                            }
                            else {
                                state.basket[index].quantity++;
                                state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                            }
                        }
                        else {
                            state.basket = [...state.basket, action.payload];
                            state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                        }
                    }
                    else {

                        var checkPrice = entity.OrderVariantVMs.find(x => x.price != 0);
                        if (checkPrice) {
                            if (entity.product.discount_Rate === 0) {
                                entity.price = entity.price + checkPrice.price;
                            }
                            else {
                                entity.price = entity.product.discountPrice;
                            }
                            state.basket = [...state.basket, entity];
                            state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                        }
                        else {
                            state.basket = [...state.basket, action.payload];
                            state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                        }
                    }

                }
                else {
                    if (entity.product.discount_Rate !== 0) {
                        entity.price = entity.product.discountPrice
                    }
                    state.basket[index].quantity++;
                    state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                }
                return;
            }
            else {
                var entity = action.payload as BasketVM;
                if (entity.OrderVariantVMs.length !== 0) {
                    state.basket = [...state.basket, entity];
                    state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                }
                else {
                    if (entity.product.discount_Rate !== 0) {
                        entity.price = entity.product.discountPrice
                    }
                    state.basket = [...state.basket, action.payload];
                    state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
                }
            }
        },
        setDecreasedQuantity: (state, action) => {
            const index = state.basket.findIndex(item => item.id === action.payload.id);
            if (index >= 0) {
                state.basket[index].quantity--;
                state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
            }
        },
        setIncreasedQuantity: (state, action) => {
            const index = state.basket.findIndex(item => item.id === action.payload.id);
            if (index >= 0) {
                state.basket[index].quantity++;
                state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
            }
        },
        setSelectedBasketVariants: (state, action) => {
            state.selectedBasketVariant = action.payload;
        },
        setSelectedBasketVariant: (state, action) => {
            state.selectedBasketVariant = [...state.selectedBasketVariant, action.payload];
        },
        setDeleteBasketItem: (state, action) => {
            state.basket = state.basket.filter((item, el) => el !== action.payload);
            state.selectedBasketVariant = state.selectedBasketVariant.filter(item => item.productID !== action.payload);
            state.total = state.basket.reduce((acc, item) => acc + item.price * item.quantity, 0);
        },
        setInitialBasket: (state) => {
            state.basket = [];
            state.total = 0;
            state.selectedBasketVariant = [];
        }

    }
})
export const {
    setInitialBasket,
    setToBasket,
    setDecreasedQuantity,
    setIncreasedQuantity,
    setSelectedBasketVariants,
    setSelectedBasketVariant,
    setDeleteBasketItem
} = basketSlice.actions
export default basketSlice.reducer
