export type Result = 'error' | 'success' | 'warning';
export enum OrderStatusNumber {
    Receipt = 1,
    Approved = 2,
    Preparing = 3,
    Shipped = 4,
    OnTheWay = 5,
    Delivered = 6,
    UnDeliverable = 7,
    Rejected = 8
}
export interface BaseEntity {
    id: number;
    dateCreate: string;
}
export enum ProductType {
    Simple = 1,
    Variant = 2
}
export enum ProductStatus {
    Publish = 1,
    Draft = 2,
    Private = 3,
    Deleted = 4
}
export enum ProductStock {
    InStock = 1,
    OutOfStock = 2
}
export enum CategoryType {
    Default = 1,
    SubCategory = 2,
    MainCategory = 3
}
export enum RoleType {
    Manager = 1,
    Employee = 2
}
export enum Status {
    Active = 1,
    InActive = 2
}
export interface Admin extends BaseEntity {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roleType: RoleType;
}
export interface LoginModel<T> {
    token: string;
    loginType: string;
    entity: T;
}
export interface Category extends BaseEntity {
    name: string;
    description: string;
    image: string;
    parent: number;
    status: Status;
    categoryType: CategoryType;
}
export interface CategoryForm {
    parent: number[];
    name: string;
    description: string;
    image: string;
    status: Status;
    categoryType: CategoryType;
}
export interface Variant extends BaseEntity {
    name: string;
    description: string;
    variantItems: VariantItem[];
}

export interface VariantItem extends BaseEntity {
    variantID: number;
    name: string;
    value: string;
    variant: Variant;
}
export interface Brands extends BaseEntity {
    name: string;
    brandImage: string;
    description: string;
    brand_Link: string;
    status: Status;
}
export interface CategoryModel extends Category {
    categories: Category[];
}
export interface Product extends BaseEntity {
    name: string;
    description: string;
    shortDescription: string;
    product_Link: string;
    categoryID: number;
    brandID: number;
    productType: ProductType;
    productStatus: ProductStatus;
    discount_Rate: number;
    stock_Quantity: number;
    sales_Quantity: number;
    price: number;
}
export interface ProductImage extends BaseEntity {
    productID: number;
    imageUrl: string;
    defaultImage: boolean;
}
export interface ProductDTO extends Product {
    productVariants: ProductVariant[];
    groupedProducts: GroupedProduct[];
    productImages: ProductImage[];
    productImage: ProductImage;
    category: Category;
    brand: Brands;
    discountPrice: number;
}
export interface Order extends BaseEntity {
    customerID: number;
    orderStatusID: number;
    note: string;
}

export interface OrderBillingAddress extends BaseEntity {
    orderID: number;
    customerID: number;
    firstName: string;
    lastName: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    district: string;
    postCode: string;
    country: string;
    email: string;
    phone: string;
}

export interface OrderShippingAddress extends BaseEntity {
    orderID: number;
    customerID: number;
    firstName: string;
    lastName: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    district: string;
    postCode: string;
    country: string;
    email: string;
    phone: string;
}

export interface OrderStatus extends BaseEntity {
    name: string;
    operationNumber: OrderStatusNumber;
}

export interface OrderStatusHistory extends BaseEntity {
    orderID: number;
    statusID: number;
    note: string;
}

export interface ProductDTO extends Product {
    productImage: ProductImage;
    productImages: ProductImage[];
    category: Category;
    brand: Brands;
    discountPrice: number;
}
export interface OrderVM extends Order {
    orderStatus: OrderStatus;
    billingAddress: OrderBillingAddress;
    shippingAddress: OrderShippingAddress;
    customer: Customer;
    orderStatusHistories: OrderStatusHistory[];
    orderBasketVMs: OrderBasketVM[];
}
export interface OrderBasketVM extends OrderBasket {
    category: Category;
    brand: Brands;
    product: Product;
    productImage: ProductImage;
    productImages: ProductImage[];
    orderVariantVMs: OrderVariantVM[];
    discountPrice: number;
}
export interface OrderBasket extends BaseEntity {
    orderID: number;
    productID: number;
    quantity: number;
    price: number;
    discountRate: number;
}
export interface OrderDTO extends Order {
    orderBillingAddress: OrderBillingAddress;
    orderShippingAddress: OrderShippingAddress;
    orderBaskets: OrderBasket[];
    orderVariants: OrderVariant[];
}
export interface Customer extends BaseEntity {
    imageUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}
export interface BasketVM {
    id: string;
    productID: number;
    quantity: number;
    price: number;
    product: ProductVM;
    OrderVariantVMs: Array<BasketVariant>;
}
export interface ProductVariant extends BaseEntity {
    productID: number;
    variantID: number;
    variantItemID: number;
    stockQuantity: number;
    salesQuantity: number;
    price: number;
    discountRate: number;
    variant: Variant;
    variantItem: VariantItem;
}
export interface ProductVariantForm {
    key: number;
    productID: number;
    variantID: number;
    variantItemID: number;
    stockQuantity: number;
    salesQuantity: number;
    price: number;
    discountRate: number;
}
export interface GroupedProduct extends BaseEntity {
    productID: number;
    groupProductID: number;
    product: Product;
}
export interface ProductVM extends Product {
    productVariants: ProductVariantVM[];
    groupedProducts: GroupedProductVM[];
    productImages: ProductImage[];
    variants: Variant[];
    productImage: ProductImage;
    category: Category;
    brand: Brands;
    discountPrice: number;
}
export interface BasketVariant extends BaseEntity {
    orderID: number;
    productID: number;
    variantID: number;
    variantItemID: number;
    price: number;
    discountRate: number;
    discountPrice: number;
}
export interface OrderVariant extends BaseEntity {
    productID: number;
    basketItemId: number;
    variantItemID: number;
    price: number;
    discountRate: number;
}
export interface GroupedProductVM extends GroupedProduct {
    discountPrice: number;
}
export interface ProductVariantVM extends ProductVariant {
    discountPrice: number;
}

export interface OrderVariantVM extends OrderVariant {
    variantItem: VariantItem;
}