import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router'
import { Brands } from '../../types/Entites'
import { postBrand } from '../../utils/api'

export default function AddBrand() {
    const navigate = useNavigate();
    const [brandForm, setBrandForm] = useState({ name: '', description: '' } as Brands)
    const addBrand = async () => {
        await postBrand(brandForm).then((res) => {
            navigate(-1);
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Box sx={{ mt: 1 }}>
            <TextField
                value={brandForm.name}
                onChange={(e) => setBrandForm({ ...brandForm, name: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Brand Name"
                name="name"
                autoFocus
            />
            <TextField
                value={brandForm.description}
                onChange={(e) => setBrandForm({ ...brandForm, description: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Brand Description"
                name="name"

            />
            <Button
                onClick={addBrand}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Save
            </Button>
        </Box>
    )
}
