import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Grid, IconButton, TextField, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router';
import { baseUrl, cancelOrder, deleteOrderBasket, getOrder, updateBasketItemQuantity, updateOrderStatus } from '../../utils/api';
import { AddCircle, Cancel, Delete, Done } from '@mui/icons-material';
import { setSelectedOrder } from '../../store/features/orderReducers';
import { setInitialBasket } from '../../store/features/basketReducers';
import { OrderBasketVM, OrderStatusNumber, OrderVM } from '../../types/Entites';
export default function EditOrder() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedOrder } = useSelector((x: RootState) => x.orderReducers);
    const [loading, setLoading] = useState(true);
    const [editableTableNo, setEditableTableNo] = useState(0);
    const [quantity, setQuantity] = useState(0);
    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        if (id != undefined) {
            await getOrder(parseInt(id)).then((res) => {
                if (res.data.entity != null) {
                    dispatch(setSelectedOrder(res.data.entity));
                    setLoading(false);
                }
                else {
                    navigate('/orders');
                }
            }).catch((er) => {
                console.log(er);
            });
        }
    }
    console.log(selectedOrder);
    const handleChangeOrderButon = async () => {
        if (selectedOrder.orderStatus.operationNumber !== OrderStatusNumber.Delivered && selectedOrder.orderStatus.operationNumber !== OrderStatusNumber.Rejected)
            await updateOrderStatus(selectedOrder.id,).then((res) => {
                window.location.reload();
            }).catch((er) => {
                console.log(er);
            });
    }
    const ProductAndVariantPrice_Sum = (orderBasketVMs: OrderBasketVM) => {
        return orderBasketVMs.price * orderBasketVMs.quantity;
    }
    return (
        loading ? <div>Loading...</div> :
            Object.keys(selectedOrder).length > 0 ?
                <Grid container>
                    <Grid item md={3} sm={3} xs={12}>
                        <h1>Order No: {selectedOrder?.id}</h1>
                        <p>
                            <b>Customer Firstname & Lastname:</b> {selectedOrder?.customer?.firstName} {selectedOrder?.customer?.lastName}<br />
                            <b>Email:</b>{selectedOrder.customer.email} <br />
                            <b>Note:</b> {selectedOrder.note}
                        </p>
                        <h1>Shipping Information</h1>
                        <p>
                            <b>Firstname & Lastname:</b> {selectedOrder.shippingAddress.firstName} {selectedOrder.shippingAddress.lastName}<br />
                            <b>Address 1:</b> {selectedOrder?.shippingAddress?.address_1}<br />
                            <b>Address 2:</b> {selectedOrder?.shippingAddress?.address_2}<br />
                            <b>City: </b>{selectedOrder.shippingAddress.city}<br />
                            <b>State:</b>{selectedOrder.shippingAddress.district} <br />
                            <b>Zip: </b>{selectedOrder.shippingAddress.postCode}<br />
                            <b>Country: </b>{selectedOrder.shippingAddress.country}<br />
                        </p>
                        <h1>Billing Information</h1>

                        <p>
                            <b>Firstname & Lastname:</b> {selectedOrder.billingAddress.firstName} {selectedOrder.billingAddress.lastName}<br />
                            <b>Address 1:</b> {selectedOrder?.billingAddress?.address_1}<br />
                            <b>Address 2:</b> {selectedOrder?.billingAddress?.address_2}<br />
                            <b>City:</b>{selectedOrder.billingAddress.city}  <br />
                            <b>State:</b>{selectedOrder.billingAddress.district}<br />
                            <b>Zip:</b>{selectedOrder.billingAddress.postCode}<br />
                            <b>Country:</b>{selectedOrder.billingAddress.country} <br />
                            <b>Phone: </b>{selectedOrder.billingAddress.phone}<br />
                            <b>Email:</b> {selectedOrder.billingAddress.email}<br />
                        </p>
                    </Grid>
                    <Grid md={9} sm={9} item xs={12}>
                        <Grid container >
                            <Grid item md={12} sm={12} xs={12}>
                                <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}><h1> Order Items - </h1>  <Button variant="contained" onClick={handleChangeOrderButon} style={{ height: '40px' }}><span>{selectedOrder.orderStatus.name}</span></Button>
                                    {
                                        selectedOrder.orderStatus.operationNumber === OrderStatusNumber.Receipt &&
                                        <>
                                            &nbsp;&nbsp;
                                            <Button variant="contained" onClick={async () => {
                                                await cancelOrder(selectedOrder.id).then((res) => {
                                                    console.log(res.data);
                                                }).catch((er) => {
                                                    console.log(er);
                                                });
                                            }} style={{ height: '40px', background: 'red' }}><span>Reject</span></Button>
                                        </>
                                    }
                                </div>
                            </Grid>

                        </Grid>
                        <table style={{ width: '100%' }} border={1}>
                            <thead>
                                <tr>
                                    <th align='left'>Procuct Image</th>
                                    <th align='left'>Product Name</th>
                                    <th align='center'>Quantity</th>
                                    <th align='center'>Total</th>

                                    {selectedOrder.orderStatus.operationNumber === OrderStatusNumber.Receipt && <th><IconButton onClick={() => {
                                        dispatch(setInitialBasket());
                                        navigate("/dashboard?orderId=" + selectedOrder.id)
                                    }}>
                                        <AddCircle />
                                    </IconButton></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder?.orderBasketVMs?.map((x, i) => (
                                    <tr key={i}>
                                        <td align='center'>
                                            <img style={{ height: '100px' }} src={baseUrl + x.productImage?.imageUrl} />
                                        </td>
                                        <td>{x.product.name} <br></br>
                                            {x.orderVariantVMs != null && x.orderVariantVMs.length !== 0 && <ul>
                                                {
                                                    x.orderVariantVMs.map((item, index) => {
                                                        return <li key={index}>{item.variantItem.variant.name} : {item.variantItem.name}</li>
                                                    })
                                                }
                                            </ul>}
                                        </td>
                                        <td align='center' onDoubleClick={() => {
                                            if (selectedOrder.orderStatus.operationNumber === OrderStatusNumber.Receipt) {
                                                setQuantity(x.quantity);
                                                setEditableTableNo(x.id);
                                            }
                                        }}>{
                                                editableTableNo == x.id ? <Grid container>
                                                    <Grid item>
                                                        <TextField
                                                            onChange={(e) => {
                                                                setQuantity(e.target.value as any);
                                                            }}
                                                            value={quantity}
                                                            id="outlined-number"
                                                            label="Quantity"
                                                            type="number"
                                                        />
                                                    </Grid>
                                                    <Grid display={"flex"} flex={1} flexDirection="row" justifyContent={"space-between"}>
                                                        <IconButton sx={{ backgroundColor: 'green' }} onClick={async () => {
                                                            setEditableTableNo(0);
                                                            await updateBasketItemQuantity(x.id, quantity).then((res) => {
                                                                if (res.data.isSuccessful) {
                                                                    dispatch(setSelectedOrder({
                                                                        ...selectedOrder,
                                                                        orderBasketVMs: selectedOrder.orderBasketVMs.map((item) => {
                                                                            if (item.id == x.id) {
                                                                                return {
                                                                                    ...item,
                                                                                    quantity: res.data.entity.quantity,
                                                                                    discountRate: res.data.entity.discountRate,
                                                                                    price: res.data.entity.price,
                                                                                };
                                                                            }
                                                                            return item;
                                                                        })
                                                                    } as OrderVM));
                                                                }
                                                                else {
                                                                    alert(res.data.exceptionMessage);
                                                                }
                                                            }).catch((er) => {
                                                                console.log(er);
                                                            })
                                                        }}>
                                                            <Done />
                                                        </IconButton>
                                                        <IconButton sx={{ backgroundColor: '#ddd' }} onClick={() => {
                                                            setEditableTableNo(0);
                                                            setQuantity(0);

                                                        }}>
                                                            <Cancel />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid> : x.quantity
                                            }</td>

                                        <td align='center'>{new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(ProductAndVariantPrice_Sum(x))}</td>

                                        {
                                            selectedOrder.orderStatus.operationNumber === OrderStatusNumber.Receipt &&
                                            <td> <IconButton onClick={async () => {
                                                await deleteOrderBasket(x.id).then((res) => {
                                                    window.location.reload();
                                                })
                                            }}>
                                                <Delete />
                                            </IconButton> </td>
                                        }

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Grid>

                </Grid > : null
    )

}
