import React from 'react'
import { BasketVM, Product, ProductDTO, ProductVM } from '../types/Entites'
import { Grid, Box, FormLabel, Button } from '@mui/material';
import { baseUrl } from '../utils/api';
import { useDispatch } from 'react-redux';
import { setToBasket } from '../store/features/basketReducers';
export default function ProductCard(props: {
    item: ProductVM,
    isActiveAddCard: boolean,
    setProductDetails: (entity: ProductVM) => void
}) {
    const dispatch = useDispatch();
    const basketVM: BasketVM = {
        productID: props.item.id,
        price: props.item.price,
        id: props.item.id.toString(),
        product: props.item,
        quantity: 1,
        OrderVariantVMs: []
    }
    const addToBasket = () => {
        if (props.item.productVariants.length !== 0) {
            props.setProductDetails(props.item);
        }
        else {
            dispatch(setToBasket(basketVM));
        }
    }
    return (
        <Grid item sm={12} md={12} xs={12} sx={{ border: '1px solid #ddd', margin: '10px', padding: '10px' }}>
            <Box sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
                {props.item.productImage != null && Object.keys(props.item.productImage).length !== 0 && <img style={{ height: '75px' }} src={baseUrl + props.item.productImage.imageUrl} />}
            </Box>
            <Box sx={{ marginTop: '10px' }}>
                <Box><FormLabel sx={{ fontSize: '14px', }}>{props.item.brand.name}</FormLabel> - <FormLabel sx={{ fontSize: '14px', }}>{props.item.name}</FormLabel></Box>
                <Box><FormLabel sx={{ fontSize: '14px', }}>{props.item.discountPrice !== 0 ? <Box>
                    <FormLabel sx={{ textDecoration: 'line-through', fontSize: '12px', marginLeft: '10px', marginRight: '10px' }}>{new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.price)}</FormLabel>
                    <FormLabel>{new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.discountPrice)}</FormLabel>
                </Box> : new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.price)}</FormLabel></Box>
                <Button onClick={addToBasket} sx={{ marginTop: '20px' }} size='small' fullWidth variant='contained'>{props.isActiveAddCard ? "Add Card" : "View"}</Button>
            </Box>
        </Grid>
    )
}

