import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    deleteOpenDialog: false,
    handleDelete: () => { },
    deleteTitle: 'Do you want to delete this item?',
    deleteMessage: 'This action cannot be undone.',
    deleteButtons: ["Cancel", "Delete"]

}
const helperSlice = createSlice({
    name: 'helper',
    initialState: INITIAL_STATE,
    reducers: {
        setDeleteOpenDialog: (state, action) => {
            state.deleteOpenDialog = action.payload;
        },
        setHandleDelete: (state, action) => {
            state.handleDelete = action.payload;
        }
    },
});
export const helperReducers = helperSlice.reducer;
export const {
    setDeleteOpenDialog,
    setHandleDelete
} = helperSlice.actions;