import React, { ChangeEvent, useEffect, useState } from 'react'
import VariantContainer from '../components/VariantContainer';
import { Box } from '@mui/system'
import { TextField, Grid, Autocomplete, Tabs, Tab, CircularProgress } from '@mui/material'
import { GroupedProduct, Product, ProductType, Variant } from '../types/Entites';
import { getSearchProduct } from '../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setSelectedProducts } from '../store/features/productReducers';
import { setSelectedVariantItems } from '../store/features/variantReducers';

export function VerticalTabs(props: { product?: Product, selectedVariants: Array<Variant>, productType: ProductType, variants: Array<Variant>, handleSelectVariant: (entity: Array<Variant>) => void, groupedProducts?: Array<GroupedProduct> }) {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Grouped Product" {...a11yProps(0)} />
                {props.productType === ProductType.Variant && <Tab label="Variant Product" {...a11yProps(1)} />}
            </Tabs>
            <TabPanel value={value} index={0}>
                <Asynchronous productId={props.product && props.product.id} groupedProducts={props.groupedProducts ? props.groupedProducts : []} />
            </TabPanel>
            {
                props.productType === ProductType.Variant &&
                <TabPanel value={value} index={1}>
                    <Grid container>
                        <Autocomplete
                            fullWidth
                            multiple
                            value={props.selectedVariants}
                            id="tags-standard"
                            options={props.variants}
                            onChange={async (e, value) => {

                                if (value.length > 0) {
                                    props.handleSelectVariant(value);
                                }
                                else {
                                    props.handleSelectVariant([]);
                                    dispatch(setSelectedVariantItems([]));
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    margin='dense'
                                    {...params}
                                    variant="outlined"
                                    label="Variant"
                                    placeholder="Variant"
                                />
                            )}
                        />
                    </Grid>
                    {
                        props.selectedVariants.length > 0 &&
                        <VariantContainer productId={props.product ? props.product.id : 0} variants={props.selectedVariants} />
                    }
                </TabPanel>
            }

        </Box>
    );
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <Grid
            sx={{ width: '100%', padding: '0px 5px' }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component={"div"} sx={{ marginLeft: '5px', width: '100%' }}>
                    {children}
                </Box>
            )
            }
        </Grid >
    );
}
function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function Asynchronous(props: { productId?: number, groupedProducts: Array<GroupedProduct> }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly Product[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { selectedProducts } = useSelector((x: RootState) => x.productReducers);
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    useEffect(() => {
        if (searchText.length === 0) {
            setOptions([]);
        }
    }, [searchText])

    return (
        <Grid container>
            <Grid item sm={12} md={12} xs={12}>
                <Autocomplete
                    id="asynchronous-demo"
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    multiple
                    onClose={() => {
                        setOpen(false);
                    }}
                    value={selectedProducts}
                    onChange={async (e, value) => {
                        dispatch(setSelectedProducts(value));

                    }}
                    noOptionsText="No Product Found"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            value={searchText}
                            onChange={async (e) => {

                                setSearchText(e.target.value);
                                setLoading(true);
                                await getSearchProduct(e.target.value).then((res) => {
                                    if (res.data) {
                                        setOptions(res.data.list);
                                    }
                                });
                                setLoading(false);
                            }}
                            margin='dense'
                            {...params}

                            label="Product Search"
                            placeholder='Product'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}