import React, { useState } from 'react'
import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router';
import SnacBar from '../components/SnacBar';
import { Customer } from '../types/Entites';
import { postCustomer } from '../utils/api';
import { ResultSnacbar, setResult } from '../store/features/resultReducers';
import { AxiosError } from 'axios';
export default function Register() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState<Customer>({ firstName: '', lastName: '', email: '', password: '' } as Customer);
    const [check, setCheck] = useState(false);

    const handleRegister = async () => {
        if (customer.firstName.length !== 0 && customer.lastName.length !== 0 && customer.email.length !== 0 && customer.password.length !== 0) {
            setCheck(false);
            setLoading(true);
            await postCustomer(customer).then((res) => {
                if (res.data.isSuccessful) {
                    dispatch(setResult({ result: 'success', message: 'You are registered', isOpen: true, isAutoHide: true } as ResultSnacbar));
                    setTimeout(() => {
                        navigate('/');
                    }, 500);
                }
                else {
                    dispatch(setResult({ result: 'warning', message: res.data.exceptionMessage?.toString(), isAutoHide: true, isOpen: true } as ResultSnacbar));
                }
            }).catch((err: AxiosError) => {
                dispatch(setResult({ result: 'warning', message: err.message?.toString(), isAutoHide: true, isOpen: true } as ResultSnacbar));
            });
        }
        else {
            setCheck(true);
        }
    }
    const handleChangeCustomer = (key: any, value: any) => {
        setCustomer({ ...customer, [key]: value });
    }
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        error={check && customer.firstName.length === 0 ? true : false}
                        value={customer.firstName}
                        onChange={(e) => {
                            handleChangeCustomer("firstName", e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="firstname"
                        label="Firstname"
                        name="firstname"
                        autoFocus
                    />
                    <TextField
                        error={check && customer.lastName.length === 0 ? true : false}
                        value={customer.lastName}
                        onChange={(e) => {
                            handleChangeCustomer("lastName", e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="LastName"
                        name="lastName"

                    />
                    <TextField
                        error={check && customer.email.length === 0 ? true : false}
                        value={customer.email}
                        onChange={(e) => {
                            handleChangeCustomer("email", e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail "
                        name="email"
                        autoComplete="email"

                    />
                    <TextField
                        error={check && customer.password.length === 0 ? true : false}
                        value={customer.password}
                        onChange={(e) => {
                            handleChangeCustomer("password", e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Box>
                        <Button
                            onClick={handleRegister}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign up
                        </Button>
                    </Box>
                    <Box justifyContent={"center"} display="flex"><Typography variant='subtitle1'>If you have an account, log in</Typography></Box>
                    <Box>
                        <Button
                            onClick={() => navigate("/")}
                            type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SnacBar />
        </Container>
    )
}
