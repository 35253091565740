import { createSlice } from "@reduxjs/toolkit"
import { Result } from "../../types/Entites";
export interface ResultSnacbar {
    message: string;
    result: Result;
    isOpen: boolean;
    isAutoHide: boolean;
}

const INITIAL_STATE = {
    resultSnackBar: { message: '', result: 'success', isOpen: false, isAutoHide: true } as ResultSnacbar
};
const resultSlice = createSlice({
    name: 'resultSlice',
    initialState: INITIAL_STATE,
    reducers: {
        setResult: (state, action) => {
            state.resultSnackBar = action.payload;
        }
    }
});
export const resultReducers = resultSlice.reducer;
export const {
    setResult
} = resultSlice.actions;