import React, { useState } from 'react'
import { Grid, Autocomplete, TextField } from '@mui/material'
import { Variant, VariantItem } from '../types/Entites'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProductVariant, setSelectedProductVariants, setSelectedVariantItem, setSelectedVariantItems, updateSelectedProductVariant } from '../store/features/variantReducers';
import { RootState } from '../store';
import { deleteAllProductVariants } from '../utils/api';
interface VariantContainerProps {
    variants: Variant[],
    productId: number
}
export default function VariantContainer(props: VariantContainerProps) {
    const dispatch = useDispatch();
    const { selectedProductVariants, selectedVariantItems } = useSelector((x: RootState) => x.variantReducers)
    const handleProductVariant = (props: { productVariantKey: number, key: any, value: any }) => {
        dispatch(updateSelectedProductVariant({
            ...selectedProductVariants.find(x => x.key == props.productVariantKey),
            [props.key]: props.value
        }))
    }
    return (
        <Grid item>
            {
                props.variants.map((item, index) => {
                    return <Grid key={index} container sx={{ marginTop: '20px' }}>
                        <Grid item sm={6} md={6} xs={12}>
                            <h2>{item.name}</h2>
                        </Grid>
                        <Grid item sm={6} md={6} xs={12}>
                            <Autocomplete
                                size="small"
                                multiple
                                id="tags-standard"
                                options={item.variantItems.filter((y) => y.variantID === item.id)}
                                value={selectedVariantItems.filter((y) => y.variantID === item.id)}
                                onChange={async (event, newValue) => {
                                    if (newValue.length > 0) {
                                        newValue.map((item) => {
                                            var check = selectedVariantItems.find(y => y.id == item.id);
                                            if (check === undefined) {
                                                dispatch(setSelectedVariantItems([...selectedVariantItems, item]));
                                                // setSelectedVariantItems([...selectedVariantItems, item])
                                                var checkVariant = selectedProductVariants.find(y => y.key == item.id)
                                                if (!checkVariant) {
                                                    dispatch(setSelectedProductVariant({
                                                        key: item.id,
                                                        variantID: item.variantID,
                                                        stockQuantity: 0,
                                                        salesQuantity: 0,
                                                        price: 0,
                                                        discountRate: 0,
                                                        variantItemID: item.id
                                                    }));
                                                }
                                                else {
                                                    dispatch(setSelectedProductVariant({
                                                        key: item.id,
                                                        variantID: item.variantID,
                                                        stockQuantity: checkVariant.stockQuantity,
                                                        salesQuantity: checkVariant.salesQuantity,
                                                        price: checkVariant.price,
                                                        discountRate: checkVariant.discountRate,
                                                        variantItemID: checkVariant.variantItemID
                                                    }))

                                                }

                                            }
                                            else {
                                                setSelectedVariantItems(selectedVariantItems.filter(x => x.id != item.id))
                                            }
                                        })
                                    }
                                    else {
                                        await deleteAllProductVariants(props.productId as any, selectedVariantItems.filter((y) => y.variantID !== item.id));
                                        dispatch(setSelectedVariantItems(selectedVariantItems.filter(x => x.variantID !== item.id)));
                                        dispatch(setSelectedProductVariants(selectedProductVariants.filter(x => x.variantID !== item.id)));
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        margin='dense'
                                        {...params}
                                        variant="outlined"
                                        label="Variant"
                                        placeholder="Variant"
                                    />
                                )}
                            />
                        </Grid>
                        {
                            selectedVariantItems.filter((y) => y.variantID == item.id).map((item, index) => {
                                return <Grid key={index} container>
                                    <Grid item sm={6} md={6} xs={12}></Grid>
                                    <Grid item sm={6} md={6} xs={6}>
                                        <h3>{item.name}</h3>
                                        <Grid spacing={2} container>
                                            <Grid item sm={6} md={6} xs={12}>
                                                <TextField
                                                    onChange={(e) => handleProductVariant({
                                                        productVariantKey: item.id,
                                                        key: 'price',
                                                        value: e.target.value.length > 0 ? parseInt(e.target.value) : 0
                                                    })}
                                                    value={selectedProductVariants.find(x => x.key == item.id)?.price}
                                                    fullWidth
                                                    margin='dense'
                                                    size='small'
                                                    variant="outlined"
                                                    label="Price"
                                                    placeholder="Price"
                                                />
                                            </Grid>
                                            <Grid item sm={6} md={6} xs={12}>
                                                <TextField
                                                    onChange={(e) => handleProductVariant({
                                                        productVariantKey: item.id,
                                                        key: 'discountRate',
                                                        value: e.target.value.length > 0 ? parseInt(e.target.value) : 0
                                                    })}
                                                    value={selectedProductVariants.find(x => x.key == item.id)?.discountRate}
                                                    fullWidth
                                                    margin='dense'
                                                    size='small'
                                                    variant="outlined"
                                                    label="Discount Rate"
                                                    placeholder="Discount Rate"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid spacing={2} container>
                                            <Grid item sm={6} md={6} xs={12}>
                                                <TextField
                                                    onChange={(e) => handleProductVariant({
                                                        productVariantKey: item.id,
                                                        key: 'stockQuantity',
                                                        value: e.target.value.length > 0 ? parseInt(e.target.value) : 0
                                                    })}
                                                    value={selectedProductVariants.find(x => x.key == item.id)?.stockQuantity}
                                                    fullWidth
                                                    margin='dense'
                                                    size='small'
                                                    variant="outlined"
                                                    label="Stock Quantity"
                                                    placeholder="Stock Quantity"
                                                />
                                            </Grid>
                                            <Grid item sm={6} md={6} xs={12}>
                                                <TextField
                                                    onChange={(e) => handleProductVariant({
                                                        productVariantKey: item.id,
                                                        key: 'salesQuantity',
                                                        value: e.target.value.length > 0 ? parseInt(e.target.value) : 0
                                                    })}
                                                    value={selectedProductVariants.find(x => x.key == item.id)?.salesQuantity}
                                                    fullWidth
                                                    margin='dense'
                                                    size='small'
                                                    variant="outlined"
                                                    label="Sales Quantity"
                                                    placeholder="Sales Quantity"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })
                        }
                    </Grid>
                })
            }
        </Grid>

    )
}
