import { createSlice } from "@reduxjs/toolkit";
import { OrderVM } from "../../types/Entites";

const INITIAL_STATE = {
    orders: [] as OrderVM[],
    selectedOrder: {} as OrderVM,
}
const orderSlice = createSlice({
    name: 'orderSlice',
    initialState: INITIAL_STATE,
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload;
        }
    }
});
export const orderReducers = orderSlice.reducer;
export const {
    setOrders,
    setSelectedOrder
} = orderSlice.actions;
