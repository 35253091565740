import React from 'react'
import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store';
import { setResult } from '../store/features/resultReducers';

function SnacBars() {
    const dispatch = useDispatch();
    const resultSnacBar = useSelector((x: RootState) => x.resultReducers.resultSnackBar);
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={resultSnacBar.isOpen}
            autoHideDuration={resultSnacBar.isAutoHide ? 1500 : null}
            onClose={() => dispatch(setResult({ ...resultSnacBar, isOpen: false }))}
        >
            <Alert onClose={() => dispatch(setResult({ ...resultSnacBar, isOpen: false }))} severity={resultSnacBar.result} sx={{ width: '100%' }}>
                {resultSnacBar.message}
            </Alert>
        </Snackbar>
    )
}
const SnacBar = React.memo(SnacBars);
export default SnacBar;