import React, { useState } from "react";
import SnacBar from "./SnacBar";
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';



import { Navigate, useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { useSelector } from 'react-redux';
import { AppBar, Collapse, createTheme, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, ThemeProvider, Toolbar } from "@mui/material";
import { AccountCircle, Menu as MenuIcon, MoveToInbox, ExpandMore, ExpandLess } from "@mui/icons-material";
import { RootState } from "../store";



import { Container } from "@mui/system";
import { NavItems } from "../utils/data";
import DeleteDialog from "./DeleteDialog";



export const ProtectedLayout = () => {
    const { user, logout, goLoginPage } = useAuth();
    const outlet = useOutlet();
    const [open, setOpen] = React.useState(false);
    const resultSnacBar = useSelector((x: RootState) => x.resultReducers.resultSnackBar);
    const deleteDialog = useSelector((x: RootState) => x.helperReducers.deleteOpenDialog);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openAnchor = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    if (!user) {
        return <Navigate to="/" />;
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Box sx={{ flexGrow: .7 }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => { setOpen(true) }}
                            sx={{ mr: 2, display: { sm: 'block' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: .5 }}></Box>
                    <IconButton
                        aria-controls={openAnchor ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openAnchor ? 'true' : undefined}
                        onClick={handleClick}
                        color="inherit"
                        size="large"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openAnchor}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {/* {user.loginType !== "Customer" && <MenuItem
                        >
                            <ListItemIcon>
                                <Edit sx={{ color: '#3C0631' }} />
                            </ListItemIcon>
                            Hesabım
                        </MenuItem>} */}
                        <MenuItem onClick={() => {
                            logout();
                            goLoginPage();
                        }}>
                            <ListItemIcon>
                                <LogoutIcon sx={{ color: '#3C0631' }} />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Box component="main">
                <Container>
                    {outlet}
                </Container>
            </Box>
            {resultSnacBar.isOpen && <SnacBar />}
            <LeftDrawerMenu isDrawerOpen={open} handleDrawerClose={() => setOpen(false)} />
            {deleteDialog && <DeleteDialog />}
        </Box>
    );


};
const LeftDrawerMenu = (drawerProps: { isDrawerOpen: boolean, handleDrawerClose: () => void }) => {
    const { logout, goLoginPage, user } = useAuth();
    const navigate = useNavigate();
    const theme = createTheme({
        typography: {
            body1: {
                fontWeight: 'bold'
            }
        },
        components: {
            MuiGrid: {
                styleOverrides: {
                    root: {
                        background: '#1A76D2',
                        paddingTop: '50px'
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: '#fff',
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: '#fff'
                    }
                }
            }
        }
    })

    return (
        <React.Fragment key={'left'}>
            <Drawer
                sx={{ '.MuiDrawer-paper': { width: '300px' } }}
                anchor={'left'}
                open={drawerProps.isDrawerOpen}
                onClose={drawerProps.handleDrawerClose}
            >
                <ThemeProvider theme={theme}>
                    <Grid flex={1}>
                        <List>
                            {
                                NavItems.map((item, index) => {
                                    if (item.auth.includes(user.loginType)) {
                                        if (item.children?.length != 0) {
                                            return <CollapseMenu
                                                icon={item.icon}
                                                handleCloseDrawer={() => drawerProps.handleDrawerClose()}
                                                name={item.name} subMenu={item.children as any} key={index} />
                                        }
                                        else {
                                            return (
                                                <ListItem key={item.name}>
                                                    <ListItemButton onClick={() => {
                                                        navigate(item.path, { replace: true })
                                                        drawerProps.handleDrawerClose();
                                                    }}>
                                                        <ListItemIcon>
                                                            {item.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        }
                                    }

                                })
                            }
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    logout();
                                    goLoginPage();
                                }}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Logout"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                </ThemeProvider>
            </Drawer>
        </React.Fragment>
    )
}
const CollapseMenu = (props: { subMenu: [], name: string, icon: any, handleCloseDrawer: () => void }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <ListItem>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.name} />
                    {open ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div">
                    {
                        props.subMenu.map((item: any, key: number) => {
                            return <ListItemButton onClick={() => {
                                navigate(item.path);
                                props.handleCloseDrawer();
                            }} key={key} sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        })
                    }
                </List>
            </Collapse>
        </>

    )
}