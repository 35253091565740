import * as React from 'react';
import Dialog from '@mui/material/Dialog';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;

}
export default function Modal(props: IModalProps) {
  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.children}
    </Dialog>
  );
}