import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Brands, Variant } from '../../types/Entites';
import { postVariant } from '../../utils/api';
import { useNavigate } from 'react-router';
export default function AddVariant() {
    const [variantForm, setVariantForm] = useState({ name: '', description: '' } as Variant)
    const navigate = useNavigate();
    const addVariant = async () => {
        await postVariant(variantForm).then((res) => {
            navigate("/variants")
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Box sx={{ mt: 1 }}>
            <TextField
                value={variantForm.name}
                onChange={(e) => setVariantForm({ ...variantForm, name: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Variant Name"
                name="name"
                autoFocus
            />
            <TextField
                value={variantForm.description}
                onChange={(e) => setVariantForm({ ...variantForm, description: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Variant Description"
                name="name"
                autoFocus
            />

            <Button
                onClick={addVariant}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Save
            </Button>
        </Box>
    )
}
