import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { Brands, Status } from '../../types/Entites'
import { getBrand, putBrand } from '../../utils/api'

export default function EditBrand() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [brandForm, setBrandForm] = useState({ name: '', description: '', status: Status.Active } as Brands)

    useEffect(() => {
        loadBrand();
    }, []);

    const loadBrand = async () => {
        await getBrand(id as any).then((res) => {
            setBrandForm(res.data.entity);
        }).catch((err) => {
            console.log(err);
        });
    }


    const updateBrand = async () => {
        await putBrand(brandForm).then((res) => {
            navigate(-1);
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Box sx={{ mt: 1 }}>
            <TextField
                value={brandForm.name}
                onChange={(e) => setBrandForm({ ...brandForm, name: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Brand Name"
                name="name"
                autoFocus
            />
            <TextField
                value={brandForm.description}
                onChange={(e) => setBrandForm({ ...brandForm, description: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Brand Description"
                name="name"

            />
            <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => {
                    setBrandForm({ ...brandForm, status: e.target.checked ? Status.Active : Status.InActive })
                }} checked={brandForm.status === Status.Active ? true : false} />} label={brandForm.status === Status.Active ? "Active" : "Passive"} />
            </FormGroup>
            <Button
                onClick={updateBrand}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Save
            </Button>
        </Box>
    )
}
