import React, { useState } from 'react'
import SnacBar from '../components/SnacBar';

import { Avatar, Box, Button, Container, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { AxiosError } from 'axios';
import { postAdminLogin, postCustomerLogin } from '../utils/api';
import { useDispatch } from 'react-redux';
import { ResultSnacbar, setResult } from '../store/features/resultReducers';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router';
export default function Login() {
    const navigate = useNavigate();

    const [userProfile, setUserProfile] = useState('Admin');

    const dispatch = useDispatch();
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('info@kivi-soft.com');
    const [password, setPassword] = useState('123456');
    const [check, setCheck] = useState(false);
    const handleLogin = async (event: any) => {

        event.preventDefault();
        if (email.length !== 0 && password.length !== 0) {
            setCheck(false);
            setLoading(true);
            if (userProfile === 'Admin') {
                await postAdminLogin(email, password).then((res) => {
                    if (!res.data.hasExceptionError) {
                        dispatch(setResult({ result: 'success', message: 'logging in', isOpen: true, isAutoHide: true } as ResultSnacbar));
                        dispatch(setResult({ result: 'success', message: '', isOpen: false, isAutoHide: true } as ResultSnacbar));
                        setLoading(false);
                        login(res.data.entity);
                    }
                    else {
                        setLoading(false);
                        dispatch(setResult({ result: 'warning', message: 'Account information is incorrect', isAutoHide: true, isOpen: true } as ResultSnacbar));
                    }

                }).catch((er: AxiosError) => {
                    setLoading(false);
                    dispatch(setResult({ result: 'error', message: er.message.toString(), isOpen: true, isAutoHide: true } as ResultSnacbar));
                });
            }
            else {
                await postCustomerLogin(email, password).then((res) => {
                    if (!res.data.hasExceptionError) {
                        dispatch(setResult({ result: 'success', message: 'logging in', isOpen: true, isAutoHide: true } as ResultSnacbar));
                        dispatch(setResult({ result: 'success', message: '', isOpen: false, isAutoHide: true } as ResultSnacbar));
                        setLoading(false);
                        login(res.data.entity);
                    }
                    else {
                        setLoading(false);
                        dispatch(setResult({ result: 'warning', message: 'Account information is incorrect', isAutoHide: true, isOpen: true } as ResultSnacbar));
                    }

                }).catch((er: AxiosError) => {
                    setLoading(false);
                    dispatch(setResult({ result: 'error', message: er.message.toString(), isOpen: true, isAutoHide: true } as ResultSnacbar));
                });
            }

        }
        else {
            setCheck(true);
        }
    };
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {userProfile} Login
                </Typography>

                <Box sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">User Profile</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userProfile}
                            label="User Profile"
                            onChange={(e) => {
                                setUserProfile(e.target.value);
                                if (e.target.value === 'Admin') {
                                    setEmail('info@kivi-soft.com');
                                    setPassword('123456');
                                }
                                else {
                                    setEmail('');
                                    setPassword('');
                                }

                            }}
                        >
                            <MenuItem value={"Admin"}>Admin</MenuItem>
                            <MenuItem value={"Customer"}>Customer</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        error={check && email.length === 0 ? true : false}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail "
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        error={check && password.length === 0 ? true : false}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        onClick={handleLogin}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? 'Loading...' : 'Sign in'}
                    </Button>
                    <Box justifyContent={"center"} display="flex"><Typography variant='subtitle1'>or</Typography></Box>
                    <Box>
                        <Button
                            onClick={() => {
                                navigate('/register');
                            }}
                            type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign up
                        </Button>
                    </Box>
                </Box>
            </Box>
            <SnacBar />
        </Container>
    )
}
