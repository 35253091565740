import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import basketReducers from './features/basketReducers';
import { helperReducers } from './features/helperReducers';
import { orderReducers } from './features/orderReducers';
import { productReducers } from './features/productReducers';

import { resultReducers } from './features/resultReducers';
import { variantReducers } from './features/variantReducers';


const rootReducer = combineReducers({
    resultReducers,
    helperReducers,
    variantReducers,
    orderReducers,
    basketReducers,
    productReducers
});
const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})
export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => customizedMiddleware,
    });
}
export const store = setupStore();
export type RootState = ReturnType<typeof store.getState>;