import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { Category, CategoryForm, CategoryType } from '../../types/Entites';
import { getCategories, getCategory, postCategory } from '../../utils/api';
import { useNavigate } from 'react-router';
export default function AddCategory() {

    const navigate = useNavigate();
    const [categoryForm, setCategoryForm] = useState({ name: '', description: '', categoryType: CategoryType.Default, status: 1 } as CategoryForm);
    const [categories, setCategories] = useState<Array<Category>>([]);
    useEffect(() => {
        loadCategories();
    }, [])
    const addCategory = async () => {
        await postCategory(categoryForm).then((res) => {
            navigate(-1);
        }).catch((err) => {
            console.log(err)
        });
    }
    const loadCategories = async () => {
        await getCategories().then((res) => {
            setCategories(res.data.list);
        }).catch((err) => { });
    }
    return (
        <Box sx={{ mt: 1 }}>
            <TextField
                value={categoryForm.name}
                onChange={(e) => setCategoryForm({ ...categoryForm, name: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Category Name"
                name="name"
                autoFocus
            />
            <TextField
                value={categoryForm.description}
                onChange={(e) => setCategoryForm({ ...categoryForm, description: e.target.value })}
                margin="dense"
                required
                fullWidth
                id="description"
                label="Category Description"
                name="description"
            />
            <FormControl margin='dense' fullWidth>
                <InputLabel id="demo-simple-select-label">Category Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryForm.categoryType}
                    label="Category Type"
                    onChange={(e) => {

                        setCategoryForm({ ...categoryForm, categoryType: e.target.value as CategoryType })
                    }}
                >
                    <MenuItem value={CategoryType.Default}>Root</MenuItem>
                    <MenuItem value={CategoryType.MainCategory}>Main Category</MenuItem>
                    <MenuItem value={CategoryType.SubCategory}>Sub Category</MenuItem>
                </Select>
            </FormControl>
            {
                categoryForm.categoryType !== CategoryType.Default &&
                <FormControl margin='dense' fullWidth>
                    <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={categories}
                        disablePortal
                        id="combo-box-demo"
                        fullWidth
                        onChange={(e, value) => {
                            setCategoryForm({ ...categoryForm, parent: [value?.id as any] });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Categories" />}
                    />
                </FormControl>}
            <Button
                onClick={addCategory}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Save
            </Button>
        </Box>
    )
}
