import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { TextField, FormControl, InputLabel, Select, MenuItem, Grid, Button } from '@mui/material'
import { Brands, Category, CategoryModel, GroupedProduct, Product, ProductType, ProductVM, Variant, VariantItem } from '../../types/Entites';
import { addGroupedProduct, addProductImage, baseUrl, deleteProductImage, getBrands, getCategories, getListCategoriesWithParent, getProduct, getVariants, postProduct, putGroupedProduct, putProduct, updateProductCoverImage, updateProductVariant } from '../../utils/api';
import { useParams } from 'react-router';
import Loading from '../../components/Loading';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProductVariant, setSelectedProductVariants, setSelectedVariantItem, setSelectedVariantItems } from '../../store/features/variantReducers';
import { RootState } from '../../store';
import { setSelectedProducts } from '../../store/features/productReducers';
import { Delete } from '@mui/icons-material';
import { VerticalTabs } from '../../components/VerticalTabs';
export default function EditProduct() {
    const dispatch = useDispatch();

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [productForm, setProductForm] = useState({ name: '', description: '', productType: ProductType.Simple, shortDescription: '', stock_Quantity: 0, sales_Quantity: 0, price: 0, discount_Rate: 0 } as ProductVM);
    const [brands, setBrands] = useState([] as Brands[]);
    const [categories, setCategories] = useState([] as CategoryModel[]);

    const [variants, setVariants] = useState([] as Variant[]);
    const [selectedVariants, setSelectedVariants] = useState([] as Variant[]);

    const [selectedBrand, setSelectedBrand] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);

    const { selectedProductVariants } = useSelector((state: RootState) => state.variantReducers);
    const groupedProducts = useSelector((state: RootState) => state.productReducers.selectedProducts);
    useEffect(() => {
        dispatch(setSelectedVariantItems([]));
        dispatch(setSelectedProductVariants([]));
        loadData();
    }, []);
    useEffect(() => {
        setSelectedVariants([]);
    }, [productForm.productType])

    useEffect(() => {
        var getCategory = categories.find((x) => x.id == productForm.categoryID);
        var getSubCategory = categories.map((y) => {
            return y.categories.find((z) => z.id === productForm.categoryID)
        }).filter((y => y != null));
        if (getCategory !== undefined) {
            setSelectedCategory(getCategory?.id);
        }
        else {
            if (getSubCategory != undefined && getSubCategory.length != 0) {
                setSelectedCategory(getSubCategory[0]?.id as any);
            }
            else {
                setSelectedCategory(0);
            }
        }
        var brandIndex = brands.findIndex((x) => x.id == productForm.brandID);
        if (brandIndex !== -1) {
            setSelectedBrand(brandIndex + 1);
        }
        else {
            setSelectedBrand(0);
        }
    }, [categories, brands])
    const getHandleVariant = () => {


        return selectedVariants;
    }
    useEffect(() => {

        if (productForm.variants != undefined) {

            variants.map((item) => {
                var check = productForm.variants.find((y) => y.id == item.id);
                if (check) {
                    setSelectedVariants([...selectedVariants, item]);
                }
            });
            if (productForm.productVariants != undefined && productForm.productVariants.length != 0) {
                productForm.productVariants.map((item) => {
                    dispatch(setSelectedVariantItem({
                        variantID: item.variantID,
                        name: item.variantItem.name,
                        value: item.variantItem.value,
                        variant: item.variant,
                        id: item.id,
                        dateCreate: item.variantItem.dateCreate,
                        variantItemID: item.variantItemID,
                    } as VariantItem));
                    dispatch(setSelectedProductVariant({
                        id: item.id,
                        key: item.id,
                        variantID: item.variantID,
                        variantItemID: item.variantItemID,
                        stockQuantity: item.stockQuantity,
                        salesQuantity: item.salesQuantity,
                        price: item.price,
                        discountRate: item.discountRate
                    }))
                });

            }
        }
    }, [variants])

    const loadData = async () => {
        await loadProduct();
        await loadBrands();
        await loadCategories();
        await loadVariants();
        setLoading(false);
    }
    const loadVariants = async () => {
        await getVariants().then((res) => {
            setVariants(res.data.list);

        }).catch((err) => {
            console.log(err);
        })
    }
    const loadProduct = async () => {
        await getProduct(id as any).then((res) => {
            setProductForm(res.data.entity);
            dispatch(setSelectedProducts(res.data.entity.groupedProducts.map((item) => item.product)));
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleProductFormChange = (key: any, value: any) => {
        setProductForm({ ...productForm, [key]: value });
    }
    const loadBrands = async () => {
        await getBrands().then((res) => {
            setBrands(res.data.list);
        }).catch((err) => {
            console.log(err);
        })
    }
    const loadCategories = async () => {
        await getListCategoriesWithParent().then((res) => {
            setCategories(res.data.list);
        }).catch((err) => {
            console.log(err);
        })
    }
    const updateProduct = async () => {
        await putProduct(productForm).then(async (res) => {
            await putGroupedProduct(groupedProducts.map((item) => {
                return ({ productID: res.data.entity.id, groupProductID: item.id }) as GroupedProduct;
            }), productForm.id);
            await updateProductVariant(selectedProductVariants.map((item) => {
                return {
                    ...item,
                    productID: res.data.entity.id
                } as any;
            }));
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });
    }
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        await addProductImage(e.target.files as any, productForm.id).then(async (res) => {
            if (res.data.isSuccessful) {
                setLoading(true);
                await loadProduct();
                setLoading(false);
            }
            else {
                alert(res.data.exceptionMessage);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Loading loading={loading}>

            <Box sx={{ marginTop: '10px' }}>
                <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'row' }}>
                    {
                        productForm.productImages != undefined &&
                        productForm.productImages.length !== 0 &&
                        productForm.productImages.map((item, index) => {
                            return <li key={index} style={{ marginRight: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img style={{ height: '75px', width: '75px' }} src={baseUrl + item.imageUrl} />
                                <Button onClick={async () => {
                                    await deleteProductImage(item.id).then((res) => {
                                        if (res.data.isSuccessful) {
                                            setProductForm({ ...productForm, productImages: productForm.productImages.filter((x) => x.id != item.id) })
                                        }
                                    })
                                }}>
                                    <Delete />
                                </Button>
                                {item.defaultImage == false && <Button onClick={async () => {
                                    await updateProductCoverImage(item.id).then(async (res) => {
                                        setLoading(true);
                                        await loadProduct();
                                        setLoading(false);
                                    });
                                }}>
                                    Kapak Resmi Yap
                                </Button>}
                            </li>
                        })
                    }
                </ul>
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload Image
                    <input
                        multiple={true}
                        onChange={handleFileChange}
                        type="file"
                        hidden
                    />
                </Button>
                <TextField
                    value={productForm.name}
                    onChange={(e) => handleProductFormChange("name", e.target.value)}
                    margin="dense"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoFocus
                />
                <TextField
                    value={productForm.description}
                    onChange={(e) => handleProductFormChange("description", e.target.value)}
                    margin="dense"
                    required
                    fullWidth
                    multiline={true}
                    rows={4}
                    id="description"
                    label="Description"
                    name="description"
                />
                <TextField
                    value={productForm.shortDescription}
                    onChange={(e) => handleProductFormChange("shortDescription", e.target.value)}
                    margin="dense"
                    required
                    fullWidth
                    id="description"
                    label="Short Description"
                    name="description"
                />
                <FormControl margin='dense' fullWidth>
                    <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBrand}
                        label="Brand"
                        onChange={(e) => {
                            setSelectedBrand(e.target.value as any);
                            handleProductFormChange("brandID", brands[e.target.value as any - 1].id);
                        }}
                    >
                        <MenuItem value={0}>{"Select"}</MenuItem>
                        {
                            brands.map((brand, index) => {
                                return <MenuItem key={index} value={index + 1}>{brand.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl margin='dense' fullWidth>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory}
                        label="Category"
                        onChange={(e) => {
                            var findCategory = categories.find((y) => y.id === e.target.value as any);
                            var subCategory = categories.find((y) => {
                                if (y.categories != null) {
                                    return y.categories.find((x) => x.id === e.target.value as any);
                                }
                                return null;
                            });
                            if (findCategory != null) {
                                handleProductFormChange("categoryID", findCategory.id);
                                setSelectedCategory(e.target.value as any);
                            }
                            else {
                                handleProductFormChange("categoryID", subCategory?.id);
                                setSelectedCategory(e.target.value as any);
                            }
                        }}
                    >
                        <MenuItem value={0}>{"Select"}</MenuItem>
                        {
                            categories.map((category, index) => {
                                if (category.categories != null && category.categories.length !== 0) {
                                    return category.categories.map((item, index) => {
                                        return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    })
                                }
                                else {
                                    return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                }
                            })
                        }
                    </Select>
                </FormControl>

                {productForm.productType === ProductType.Simple && <Grid container columnSpacing={2}>
                    <Grid item sm={6} md={6} xs={6}>
                        <TextField
                            value={productForm.stock_Quantity}
                            onChange={(e) => handleProductFormChange("stock_Quantity", e.target.value)}
                            margin="dense"
                            required
                            fullWidth
                            id="stockquantity"
                            label="Stock Quantity"
                            name="stockquantity"
                        />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                        <TextField
                            value={productForm.sales_Quantity}
                            onChange={(e) => handleProductFormChange("sales_Quantity", e.target.value)}
                            margin="dense"
                            required
                            fullWidth
                            id="salesquantity"
                            label="Sales Quantity"
                            name="salesquantity"
                        />
                    </Grid>
                </Grid>}
                <Grid container columnSpacing={2}>
                    <Grid item sm={6} md={6} xs={6}>
                        <TextField
                            value={productForm.price}
                            onChange={(e) => handleProductFormChange("price", e.target.value)}
                            margin="dense"
                            required
                            fullWidth
                            id="price"
                            label="Price"
                            name="price"
                        />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                        <TextField
                            value={productForm.discount_Rate}
                            onChange={(e) => handleProductFormChange("discount_Rate", e.target.value)}
                            margin="dense"
                            required
                            fullWidth
                            id="discount_Rate"
                            label="Discount Rate"
                            name="discount_Rate"
                        />
                    </Grid>
                </Grid>
                <FormControl margin='dense' fullWidth>
                    <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={productForm.productType}
                        label="Product Type"
                        onChange={(e) => {
                            handleProductFormChange("productType", e.target.value as any);
                        }}
                    >

                        <MenuItem value={ProductType.Simple}>Basic</MenuItem>
                        <MenuItem value={ProductType.Variant}>Variant</MenuItem>

                    </Select>
                </FormControl>
                <Grid container>
                    <VerticalTabs
                        groupedProducts={productForm.groupedProducts}
                        product={productForm}
                        variants={variants}
                        productType={productForm.productType}
                        handleSelectVariant={(entity) => setSelectedVariants(entity)}
                        selectedVariants={getHandleVariant()} />
                </Grid>
                <Button
                    onClick={updateProduct}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>
            </Box >
        </Loading>
    )
}
