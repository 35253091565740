import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading';
import ProductCard from '../components/ProductCard';
import Modal from '../components/Modal';


import { ListItemButton, List, Collapse, Grid, ListItemText, Box, FormControl, RadioGroup, Radio, FormLabel, TextField, FormControlLabel, Checkbox, DialogContent, DialogTitle, DialogActions, Button, IconButton } from '@mui/material';
import { BasketVariant, BasketVM, Brands, OrderBillingAddress, OrderShippingAddress, CategoryModel, ProductVariantVM, ProductVM, CategoryType, Category } from '../types/Entites';
import { addOrder, getProducts, addProductToCreatedBasket, getListCategoriesWithParent, getBrands } from '../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setDeleteBasketItem, setSelectedBasketVariant, setSelectedBasketVariants, setToBasket } from '../store/features/basketReducers';
import { Delete } from '@mui/icons-material';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAuth } from '../hooks/useAuth';



export default function Dashboard() {
    const { user } = useAuth();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<Array<ProductVM>>([]);
    const { basket } = useSelector((x: RootState) => x.basketReducers);
    const [isDetailModal, setIsDetailModal] = useState(false);
    const [productDetail, setProductDetail] = useState<ProductVM>({} as ProductVM);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const selectedBasketVariant = useSelector((x: RootState) => x.basketReducers.selectedBasketVariant);

    const [orderShippingAddressShow, setOrderShippingAddressShow] = useState(false);
    const [orderBillingAddressShow, setOrderBillingAddressShow] = useState(false);

    const [shippingAddress, setShippingAddress] = useState<OrderBillingAddress>({
        firstName: 'Özkan',
        lastName: 'Kocakaplan',
        company: 'Kivi',
        address_1: 'Toros Mahallesi 805 Sokak No: 8/33',
        address_2: 'Toros Mahallesi 805 Sokak No: 8/33',
        city: 'Antalya',
        district: 'Konyaaltı',
        postCode: '07070',
        country: 'Turkey',
        email: 'ozkankocakaplan07@gmail.com',
        phone: '12345678'
    } as OrderBillingAddress);
    const [billingAddress, setBillingAddress] = useState<OrderShippingAddress>({ firstName: '', lastName: '', company: '', address_1: '', address_2: '', city: '', district: '', postCode: '', country: '', email: '', phone: '' } as OrderShippingAddress);

    const [inUseShippingAddress, setInUseShippingAddress] = useState(false);

    const [categories, setCategories] = useState<Array<CategoryModel>>([] as CategoryModel[]);
    const [brands, setBrands] = useState<Array<Brands>>([]);

    const [selectedBrand, setSelectedBrand] = useState<Brands>({} as Brands);
    const [selectedCategory, setSelectedCategory] = useState<any>({} as any);



    useEffect(() => {

        loadData();
        return () => {
            setLoading(true);
        }
    }, []);

    const getQueryParams = () => {
        var queryParams = new URLSearchParams(window.location.search);
        return queryParams.get("orderId");
    }
    const loadData = async () => {
        await getProducts().then((res) => {
            setProducts(res.data.list);
        }).catch((er) => {
            console.log(er);
        })
        await getListCategoriesWithParent().then((res) => {
            setCategories(res.data.list);
        }).catch((er) => console.log(er));
        await getBrands().then((res) => {
            setBrands(res.data.list);
        }).catch((er) => console.log(er));
        setLoading(false);
    }
    const handleProductDetails = React.useCallback((item: ProductVM) => {
        setProductDetail(item);
        setIsDetailModal(true);
    }, []);
    const addBasket = () => {
        const basketVM: BasketVM = {
            productID: productDetail.id,
            price: productDetail.price,
            id: productDetail.id.toString(),
            product: productDetail,
            quantity: 1,
            OrderVariantVMs: selectedBasketVariant
        }
        dispatch(setToBasket(basketVM));
        setIsDetailModal(false);
    }
    const handleShippingAddressChange = (key: any, value: any) => {
        setShippingAddress({ ...shippingAddress, [key]: value });
    }
    const handleBillingAddressChange = (key: any, value: any) => {
        setBillingAddress({ ...billingAddress, [key]: value });
    }
    const handleAddOrder = async () => {
        var orderDto = {
            note: '',
            orderBillingAddress: billingAddress,
            orderShippingAddress: shippingAddress,
            orderBaskets: basket as any,
            orderVariants: selectedBasketVariant.map((item) => {
                return {
                    variantItemID: item.variantItemID,
                    quantity: 1,
                    price: item.price,
                    discountRate: item.discountRate,
                    productID: item.productID,
                }
            })
        }
        console.log(orderDto);
        var params = getQueryParams();
        if (params !== null) {
            setCheckoutLoading(true);
            await addProductToCreatedBasket(orderDto as any, parseInt(params)).then((res) => {
                if (res.data.isSuccessful) {
                    setCheckoutLoading(false);
                    alert('Order added');
                    window.location.reload();
                }
                else {
                    alert(res.data.exceptionMessage);
                }
            }).catch((er) => {
                console.log(er);
            })
        }
        else {
            for (const key in shippingAddress) {
                if (shippingAddress[key as keyof OrderShippingAddress].toString().length === 0) {
                    setOrderShippingAddressShow(true);
                    return alert('Please fill shipping address');
                }
            }
            for (const key in billingAddress) {
                if (billingAddress[key as keyof OrderBillingAddress].toString().length === 0) {
                    setOrderBillingAddressShow(true);
                    return alert('Please fill billing address');
                }
            }
            setCheckoutLoading(true);
            await addOrder(orderDto as any).then((res) => {
                if (res.data.isSuccessful) {
                    setCheckoutLoading(false);
                    alert('Order added');
                    window.location.reload();
                }
                else {
                    alert(res.data.exceptionMessage);
                }
            }).catch((er) => {
                console.log(er);
            })
        }
    }
    const ProductPrice = (item: BasketVM) => {
        var productPrice = item.price * item.quantity;
        if (item.OrderVariantVMs.length !== 0) {
            item.OrderVariantVMs.forEach((variant) => {
                productPrice += variant.price * item.quantity;
            })
        }
        return productPrice;
    }
    const TotalPrice = () => {
        return basket.reduce((a, b) => {
            var productPrice = b.price * b.quantity;
            if (b.OrderVariantVMs.length !== 0) {
                b.OrderVariantVMs.forEach((variant) => {
                    productPrice += variant.price * b.quantity;
                }
                )
            }
            return a = a + productPrice;
        }, 0);
    }
    const checkIsAdminBasketButton = user.loginType === "Admin" && getQueryParams() != null ? true : user.loginType === "Customer" ? true : false
    return (
        <Loading loading={loading}>
            <Grid container>
                <Grid item sm={3} md={3} xs={12} sx={{ marginTop: '30px' }}>
                    <Grid item sm={12} md={12} xs={12}>
                        <FormControl>
                            <FormLabel id="categories">Categories</FormLabel>
                            <RadioGroup
                                aria-labelledby="categories"
                                defaultValue="All"
                                name="categories"
                            >
                                <ListItemButton onClick={() => {
                                    setSelectedCategory({} as CategoryModel)
                                }}>
                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                </ListItemButton>
                                {
                                    categories != null && categories.length !== 0 &&
                                    categories.map((item, index) => {
                                        if (item.categories.length !== 0) {
                                            return <List key={index}>
                                                <CategoryListCollapse handleChangeCategory={(data: Category) => setSelectedCategory(data)} categoryName={item.name} entity={item.categories} />
                                            </List>
                                        }
                                        return <ListItemButton onClick={() => {
                                            setSelectedCategory(item);
                                        }} key={index}>
                                            <FormControlLabel key={index} value={item.name} control={<Radio />} label={item.name} />
                                        </ListItemButton>
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={12} xs={12}>
                        <FormControl>
                            <FormLabel id="brands">Brands</FormLabel>
                            <RadioGroup
                                aria-labelledby="brands"
                                defaultValue="All"
                                name="brands"
                            >
                                <FormControlLabel value="All" control={<Radio onChange={() => {
                                    setSelectedBrand({} as Brands);
                                }} />} label="All" />
                                {
                                    brands != null && brands.length !== 0 &&
                                    brands.map((item, index) => {
                                        return <FormControlLabel key={index} value={item.name} control={<Radio
                                            onChange={(e) => {
                                                setSelectedBrand(item);
                                            }} />} label={item.name} />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid sx={{ maxHeight: '750px', overflowY: 'scroll' }} item sm={5} md={5} xs={12}>
                    {
                        products.filter((y) => {
                            if (Object.keys(selectedBrand).length === 0 && Object.keys(selectedCategory).length === 0) {
                                return true;
                            }
                            else {
                                if (Object.keys(selectedBrand).length !== 0 && Object.keys(selectedCategory).length !== 0) {
                                    return y.brandID === selectedBrand.id && y.categoryID === selectedCategory.id;
                                }
                                else if (Object.keys(selectedBrand).length !== 0) {
                                    return y.brandID === selectedBrand.id;
                                }
                                else if (Object.keys(selectedCategory).length !== 0) {
                                    return y.categoryID === selectedCategory.id;
                                }
                            }
                        }).map((item, index) => {
                            return <ProductCard
                                isActiveAddCard={checkIsAdminBasketButton}
                                setProductDetails={handleProductDetails}
                                key={index} item={item} />
                        })
                    }
                </Grid>
                <Grid item sm={4} md={4} xs={12}>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <h1>Basket</h1>
                        <table>
                            <tbody>
                                {
                                    basket.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <IconButton onClick={() => {
                                                        dispatch(setDeleteBasketItem(index));
                                                    }}>
                                                        <Delete />
                                                    </IconButton>
                                                </td>
                                                <td>{item.quantity}</td>
                                                <td>{item.product.brand.name} - {item.product.name}</td>
                                                <td>{new Intl.NumberFormat("de-De", {
                                                    style: "currency",
                                                    currency: "EUR"
                                                }).format(ProductPrice(item))}</td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <FormLabel component="legend">Total Price: {new Intl.NumberFormat("de-De", {
                            style: "currency",
                            currency: "EUR"
                        }).format(TotalPrice())}</FormLabel>
                        {
                            user.loginType === "Admin" && getQueryParams() != null ? <Button
                                onClick={handleAddOrder}
                                sx={{ marginTop: '20px' }}
                                fullWidth variant='outlined'>{checkoutLoading ? "Loading..." : "Checkout"}</Button>
                                : user.loginType === "Customer" && <Button
                                    onClick={handleAddOrder}
                                    sx={{ marginTop: '20px' }}
                                    fullWidth variant='outlined'>{checkoutLoading ? "Loading..." : "Checkout"}</Button>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Modal onClose={() => { setIsDetailModal(false) }} open={isDetailModal}>
                <DialogTitle>{productDetail.name}</DialogTitle>
                <DialogContent>
                    {
                        productDetail.productVariants !== undefined && productDetail.productVariants.length !== 0 &&
                        <Grid>
                            <Grid item sm={12} md={12} xs={12}>
                                {
                                    productDetail.variants.map((item) => {
                                        return <div key={item.id}>
                                            <h2>{item.name}</h2>
                                            <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap', gap: '10px' }}>
                                                {
                                                    productDetail.productVariants.filter((y) => y.variantID === item.id).map((productVariant) => {
                                                        return <VariantItemsCard productVM={productDetail} key={productVariant.id} item={productVariant} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => {
                        if (checkIsAdminBasketButton) {
                            addBasket();
                        }
                        else {
                            setIsDetailModal(false);
                        }
                    }}>{!checkIsAdminBasketButton ? "Close" : "Add Basket"}</Button>
                </DialogActions>

            </Modal>
            <Modal onClose={() => setOrderShippingAddressShow(false)} open={orderShippingAddressShow}>
                <DialogTitle>Shipping Address</DialogTitle>
                <DialogContent>
                    <Grid sx={{ marginTop: '10px' }} container spacing={1}>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.firstName}
                                onChange={(e) => {
                                    handleShippingAddressChange("firstName", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='First Name'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.lastName}
                                onChange={(e) => {
                                    handleShippingAddressChange("lastName", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Last Name'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.email}
                                onChange={(e) => {
                                    handleShippingAddressChange("email", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='E-mail'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.phone}
                                onChange={(e) => {
                                    handleShippingAddressChange("phone", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Phone'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={shippingAddress.company}
                                onChange={(e) => {
                                    handleShippingAddressChange("company", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Company'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={shippingAddress.address_1}
                                onChange={(e) => {
                                    handleShippingAddressChange("address_1", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                multiline={true}
                                rows={3}
                                label='Address 1'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={shippingAddress.address_2}
                                onChange={(e) => {
                                    handleShippingAddressChange("address_2", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                multiline={true}
                                rows={3}
                                label='Address 2'
                                variant='outlined'
                            />
                        </Grid>

                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.postCode}
                                onChange={(e) => {
                                    handleShippingAddressChange("postCode", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Post code'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.district}
                                onChange={(e) => {
                                    handleShippingAddressChange("district", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='District'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.city}
                                onChange={(e) => {
                                    handleShippingAddressChange("city", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='City'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={shippingAddress.country}
                                onChange={(e) => {
                                    handleShippingAddressChange("country", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Country'
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => {
                        setOrderShippingAddressShow(false)
                        setOrderBillingAddressShow(true)
                    }}>Ok</Button>
                </DialogActions>
            </Modal>
            <Modal onClose={() => setOrderBillingAddressShow(false)} open={orderBillingAddressShow}>
                <DialogTitle>Billing Address</DialogTitle>
                <DialogContent>
                    <Grid sx={{ marginTop: '10px' }} container spacing={1}>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.firstName}
                                onChange={(e) => {
                                    handleBillingAddressChange("firstName", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='First Name'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.lastName}
                                onChange={(e) => {
                                    handleBillingAddressChange("lastName", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Last Name'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.email}
                                onChange={(e) => {
                                    handleBillingAddressChange("email", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='E-mail'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.phone}
                                onChange={(e) => {
                                    handleBillingAddressChange("phone", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Phone'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={billingAddress.company}
                                onChange={(e) => {
                                    handleBillingAddressChange("company", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Company'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={billingAddress.address_1}
                                onChange={(e) => {
                                    handleBillingAddressChange("address_1", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                multiline={true}
                                rows={3}
                                label='Address 1'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12}>
                            <TextField
                                value={billingAddress.address_2}
                                onChange={(e) => {
                                    handleBillingAddressChange("address_2", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                multiline={true}
                                rows={3}
                                label='Address 2'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.postCode}
                                onChange={(e) => {
                                    handleBillingAddressChange("postCode", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Post code'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.district}
                                onChange={(e) => {
                                    handleBillingAddressChange("district", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='District'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.city}
                                onChange={(e) => {
                                    handleBillingAddressChange("city", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='City'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item sm={6} md={6} xs={6}>
                            <TextField
                                value={billingAddress.country}
                                onChange={(e) => {
                                    handleBillingAddressChange("country", e.target.value);
                                }}
                                size='small'
                                fullWidth
                                label='Country'
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <DialogActions>
                        <Grid container display={"flex"} justifyContent="space-between" flex={1} >
                            <FormControlLabel control={<Checkbox defaultChecked={inUseShippingAddress} onChange={(e) => {
                                setInUseShippingAddress(e.target.checked);
                                if (e.target.checked) {
                                    setBillingAddress(shippingAddress);
                                }
                                else {
                                    setBillingAddress({
                                        firstName: "",
                                        lastName: "",
                                        email: "",
                                        phone: "",
                                        company: "",
                                        address_1: "",
                                        address_2: "",
                                        postCode: "",
                                        district: "",
                                        city: "",
                                        country: ""
                                    } as any);
                                }
                            }} />} label="My order address is the same as my billing address" />
                            <Button variant='contained' onClick={() => {
                                setOrderBillingAddressShow(false)
                                handleAddOrder();
                            }}>Checkout</Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Modal>
        </Loading>
    )
}
const VariantItemsCard = (props: { item: ProductVariantVM, productVM: ProductVM }) => {
    const dispatch = useDispatch();

    const { selectedBasketVariant } = useSelector((x: RootState) => x.basketReducers);
    const isSelected = selectedBasketVariant.length != 0 && selectedBasketVariant.find(y => y.variantItemID === props.item.variantItemID && y.productID === props.productVM.id) != undefined ? true : false;
    const handleAddVariantItem = () => {
        var check = selectedBasketVariant.find(y => y.variantID === props.item.variantID && y.productID == props.productVM.id);
        if (check !== undefined) {
            const sameVariantCount = selectedBasketVariant.filter(y => y.variantID !== props.item.variantID && y.productID == props.productVM.id);
            dispatch(setSelectedBasketVariants(sameVariantCount))

            dispatch(setSelectedBasketVariant({
                variantItemID: props.item.variantItemID,
                productID: props.productVM.id,
                price: props.item.discountRate === 0 ? props.item.price : props.item.discountPrice,
                variantID: props.item.variantID,
                discountRate: props.item.discountRate,
                discountPrice: props.item.discountPrice
            } as BasketVariant));
        }
        else {
            dispatch(setSelectedBasketVariant({
                variantItemID: props.item.variantItemID,
                productID: props.productVM.id,
                price: props.item.discountRate === 0 ? props.item.price : props.item.discountPrice,
                variantID: props.item.variantID,
                discountRate: props.item.discountRate,
                discountPrice: props.item.discountPrice
            } as BasketVariant));
        }

    }
    return (
        <div
            onClick={handleAddVariantItem}
            style={{ lineHeight: '25px', padding: '0px 10px', textAlign: 'center', width: '150px', fontSize: '16px', fontWeight: '500', cursor: 'pointer', borderRadius: '5px', maxWidth: '200px', border: '1px solid #1565c0', color: isSelected ? '#fff' : '#1565c0', background: isSelected ? '#1565c0' : 'transparent' }}>
            <div>{props.item.variantItem.name}</div>
            <FormLabel sx={{ fontSize: '14px', }}>{props.item.discountPrice !== 0 ? <Box>
                <FormLabel sx={{ textDecoration: 'line-through', fontSize: '12px', marginLeft: '10px', marginRight: '10px' }}>{new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.price)}</FormLabel>
                <FormLabel>{new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.discountPrice)}</FormLabel>
            </Box> : props.item.price !== 0 && new Intl.NumberFormat("de-De", { currency: 'EUR', style: 'currency' }).format(props.item.price)}</FormLabel>
        </div>
    )
}
const CategoryListCollapse = (props: { entity: Array<Category>, categoryName: string, handleChangeCategory: (data: Category) => void }) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={props.categoryName} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        props.entity.map((x, index) => {
                            return <ListItemButton onClick={() => {
                                props.handleChangeCategory(x);
                            }} key={index} sx={{ pl: 4 }}>
                                <FormControlLabel key={index} value={x.name} control={<Radio
                                />} label={x.name} />
                            </ListItemButton>
                        })
                    }

                </List>
            </Collapse>
        </>
    )
}