import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Admin, LoginModel } from "../types/Entites";
import { useLocalStorage } from "./useLocalStroage";

interface IAuthContext {
  user: LoginModel<Admin>,
  login: (data: any) => void,
  logout: () => void,
  goLoginPage: () => void
}
const state = {
  user: {} as LoginModel<Admin>,
  login: () => { },
  logout: () => { },
  goLoginPage: () => { }
}
const AuthContext = createContext<IAuthContext>(state);

export const AuthProvider = (props: { children: any }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const login = async (data: any) => {
    setUser(data);
    if (data.loginType === "Admin") {
      navigate("/orders", { replace: false });
    }
    else {
      navigate("/dashboard", { replace: false });
    }

  };

  const logout = () => {
    setUser(null);
  };
  const goLoginPage = () => {
    navigate("/", { replace: false });
  }
  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      goLoginPage
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
