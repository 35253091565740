import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading';
import DataTable, { EnhancedTableToolbarProps } from '../../components/DataTable';

import { Box, TextField, Button, Grid, Paper, alpha, TableBody, Typography, IconButton, Tooltip, TableRow, Toolbar, TableCell, Checkbox } from '@mui/material'
import { useParams } from 'react-router';
import { getVariant, postVariantItem, putVariant, putVariantItem } from '../../utils/api';
import { Variant, VariantItem } from '../../types/Entites';
import { getComparator, Order, stableSort } from '../../components/TableHelper';
import { Add, Delete, Edit } from '@mui/icons-material';
import { VariantItemTableHeadCells } from '../../utils/TableHeadCells';
import FullScreenDialog from '../../components/FullScreenDialog';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteOpenDialog, setHandleDelete } from '../../store/features/helperReducers';
import { setVariantForm, setVariantItem, setVariantItemForm, setVariantItems } from '../../store/features/variantReducers';
import { RootState } from '../../store';
export default function EditVariant() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { variantItems, variantForm, variantItemForm } = useSelector((state: RootState) => state.variantReducers);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof VariantItem>('id');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    useEffect(() => {
        loadVariant();
    }, []);
    const loadVariant = () => {
        getVariant(id as any).then((res) => {
            dispatch(setVariantForm(res.data.entity));
            dispatch(setVariantItems(res.data.entity.variantItems));

        }).catch((er) => { });
        setLoading(false);
    }
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - variantItems.length) : 0;
    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const { numSelected } = props;
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {props.tableName}
                    </Typography>
                )}
                {numSelected == 0 && <IconButton onClick={props.goAddPage}>
                    <Add />
                </IconButton>}
                {numSelected == 1 &&
                    <Tooltip title="Düzenle">
                        <IconButton onClick={props.goEditPage}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }
                {
                    numSelected > 0 &&
                    <Tooltip title="Sil">
                        <IconButton onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar >
        );
    };
    const addVariantItem = async () => {
        await postVariantItem({ ...variantItemForm, variantID: id as any }).then((res) => {
            setAddDialog(false);
            dispatch(setVariantItem(res.data.entity));
            window.location.reload();
        }).catch((er) => { });
    }
    const updateVariantItem = async () => {
        await putVariantItem(variantItemForm).then((res) => {
            dispatch(setVariantItems(variantItems.map((item) => item.id === variantItemForm.id ? variantItemForm : item)));
            setEditDialog(false);
            window.location.reload();
        }).catch((er) => { });
    }
    const updateVariant = async () => {
        await putVariant(variantForm).then((res) => {
            dispatch(setVariantForm(res.data.entity));
            window.location.reload();
        }).catch((er) => { });
    }
    const handleDeleteVariantItem = () => {
        console.log("delete variant item");
    }
    return (
        <Loading loading={loading}>
            <Box sx={{ mt: 1 }}>
                <TextField
                    value={variantForm.name}
                    onChange={(e) => {
                        dispatch(setVariantForm({ ...variantForm, name: e.target.value }));
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Variant Name"
                    name="name"
                    autoFocus
                />
                <TextField
                    value={variantForm.description}
                    onChange={(e) => {
                        dispatch(setVariantForm({ ...variantForm, description: e.target.value }));
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="description"
                    label="Variant Description"
                    name="description"
                    autoFocus
                />
                <Grid container >
                    <Grid item xs={12}>
                        <Paper sx={{ width: '100%' }}>
                            {
                                <DataTable
                                    EnhancedTableToolbar={EnhancedTableToolbar}
                                    rows={variantItems.map((item) => item.id.toString())}
                                    HeadCell={VariantItemTableHeadCells}
                                    order={order}
                                    setOrder={(data) => setOrder(data)}
                                    orderBy={orderBy}
                                    setOrderBy={(data) => setOrderBy(data)}
                                    selected={selected}
                                    setSelected={(data) => setSelected(data)}
                                    page={page}
                                    setPage={(page) => setPage(page)}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={(data) => setRowsPerPage(data)}
                                    goEditPage={() => {
                                        dispatch(setVariantItemForm(variantItems.find((item) => item.id.toString() == selected[0])))
                                        setEditDialog(true);
                                    }}
                                    goAddPage={() => { setAddDialog(true) }}
                                    handleDelete={() => {
                                        dispatch(setDeleteOpenDialog(true))
                                        dispatch(setHandleDelete(handleDeleteVariantItem))
                                    }}
                                    tableName="Variant Item"
                                    tableBody={variantItems !== undefined && variantItems.length !== 0 &&
                                        <TableBody>
                                            {stableSort(variantItems, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.id.toString());
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleClick(event, row.id.toString())}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.name}</TableCell>
                                                            <TableCell>{row.value}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 53 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    }
                                />}
                        </Paper>
                    </Grid>
                </Grid>
                <Button
                    onClick={updateVariant}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Kaydet
                </Button>
            </Box>
            <FullScreenDialog open={addDialog} handleClose={() => {
                setAddDialog(false);
                dispatch(setVariantItemForm({ name: '', value: '' }))
            }}>
                <Grid item sm={12} md={12} xs={12} sx={{ m: 2 }}>
                    <TextField
                        value={variantItemForm.name}
                        onChange={(e) => {
                            dispatch(setVariantItemForm({ ...variantItemForm, name: e.target.value }));
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Variant Item Name"
                        name="name"
                        autoFocus
                    />
                    <TextField
                        value={variantItemForm.value}
                        onChange={(e) => {
                            dispatch(setVariantItemForm({ ...variantItemForm, value: e.target.value }));
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Variant Item Value"
                        name="name"
                        autoFocus
                    />
                    <Button
                        onClick={addVariantItem}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Kaydet
                    </Button>
                </Grid>

            </FullScreenDialog>
            <FullScreenDialog open={editDialog} handleClose={() => {
                setEditDialog(false);
                dispatch(setVariantItemForm({ name: '', value: '' }));
                setSelected([]);
            }}>
                <Grid item sm={12} md={12} xs={12} sx={{ m: 2 }}>
                    <TextField
                        value={variantItemForm.name}
                        onChange={(e) => {
                            dispatch(setVariantItemForm({ ...variantItemForm, name: e.target.value }));
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Variant Item Name"
                        name="name"
                        autoFocus
                    />
                    <TextField
                        value={variantItemForm.value}
                        onChange={(e) => {
                            dispatch(setVariantItemForm({ ...variantItemForm, value: e.target.value }));
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Variant Item Value"
                        name="name"
                        autoFocus
                    />
                    <Button
                        onClick={updateVariantItem}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Kaydet
                    </Button>
                </Grid>

            </FullScreenDialog>
        </Loading>
    )
}
