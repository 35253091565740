import React, { ChangeEvent, useEffect, useState } from 'react'
import VariantContainer from '../../components/VariantContainer';
import { Box } from '@mui/system'
import { TextField, FormControl, InputLabel, Select, MenuItem, Grid, Button, Autocomplete, Tabs, Tab, CircularProgress } from '@mui/material'
import { Brands, Category, CategoryModel, CategoryType, GroupedProduct, Product, ProductDTO, ProductType, ProductVariantForm, Variant } from '../../types/Entites';
import { getBrands, getListCategoriesWithParent, getSearchProduct, getVariants, postProduct, addGroupedProduct, addProductVariant, deleteAllGroupedProducts, getCategories } from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setSelectedProducts } from '../../store/features/productReducers';
import { setSelectedVariantItems } from '../../store/features/variantReducers';
import { useNavigate } from 'react-router';
import { VerticalTabs } from '../../components/VerticalTabs';
export default function AddProduct() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [productForm, setProductForm] = useState({ name: '', description: '', productType: ProductType.Simple, shortDescription: '', stock_Quantity: 0, sales_Quantity: 0, price: 0, discount_Rate: 0 } as Product);
    const [variants, setVariants] = useState([] as Variant[]);
    const [brands, setBrands] = useState([] as Brands[]);
    const [categories, setCategories] = useState([] as CategoryModel[]);

    const [selectedBrand, setSelectedBrand] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedVariants, setSelectedVariants] = useState([] as Variant[]);
    const selectedProductVariants = useSelector((state: RootState) => state.variantReducers.selectedProductVariants);
    const groupedProducts = useSelector((state: RootState) => state.productReducers.selectedProducts);
    const [fileList, setFileList] = useState<FileList | null>(null);
    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        loadBrands();
        loadCategories();
        loadVariants();
        setLoading(false);
    }

    const handleProductFormChange = (key: any, value: any) => {
        setProductForm({ ...productForm, [key]: value });
    }
    const loadVariants = async () => {
        await getVariants().then((res) => {
            setVariants(res.data.list);
        }).catch((err) => {
            console.log(err);
        })
    }
    const loadBrands = async () => {
        await getBrands().then((res) => {
            setBrands(res.data.list);
        }).catch((err) => {
            console.log(err);
        })
    }
    const loadCategories = async () => {
        await getListCategoriesWithParent().then((res) => {

            setCategories(res.data.list);
        }).catch((err) => {
            console.log(err);
        })
    }
    const addProduct = async () => {
        await postProduct(productForm, fileList).then(async (res) => {
            await addGroupedProduct(groupedProducts.map((item) => {
                return ({ productID: res.data.entity.id, groupProductID: item.id }) as GroupedProduct;
            }));
            await addProductVariant(selectedProductVariants.map((item) => {
                return {
                    ...item,
                    productID: res.data.entity.id,
                    variantItemID: item.key
                } as any;
            }));
            navigate('/products');
        }).catch((err) => {
            console.log(err);
        });
    }
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
    };
    const files = fileList ? [...fileList as any] : [];
    return (
        <Box sx={{ marginTop: '10px' }}>
            <Grid container>
                <ul>
                    {files.map((file, i) => (
                        <li key={i}>
                            <img src={URL.createObjectURL(file)} alt={file.name} style={{ height: '75px', width: '75px' }} />
                        </li>
                    ))}
                </ul>
            </Grid>
            <Button
                variant="contained"
                component="label"
            >
                Upload Image
                <input
                    multiple={true}
                    onChange={handleFileChange}
                    type="file"
                    hidden
                />
            </Button>
            <TextField
                value={productForm.name}
                onChange={(e) => handleProductFormChange("name", e.target.value)}
                margin="dense"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
            />
            <TextField
                multiline={true}
                rows={4}
                value={productForm.description}
                onChange={(e) => handleProductFormChange("description", e.target.value)}
                margin="dense"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
            />
            <TextField
                value={productForm.shortDescription}
                onChange={(e) => handleProductFormChange("shortDescription", e.target.value)}
                margin="dense"
                required
                fullWidth
                id="description"
                label="Short Description"
                name="description"
            />
            <FormControl margin='dense' fullWidth>
                <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedBrand}
                    label="Brand"
                    onChange={(e) => {
                        setSelectedBrand(e.target.value as any);
                        handleProductFormChange("brandID", brands[e.target.value as any - 1].id);
                    }}
                >
                    <MenuItem value={0}>{"Select"}</MenuItem>
                    {
                        brands.map((brand, index) => {
                            return <MenuItem key={index} value={index + 1}>{brand.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <FormControl margin='dense' fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    label="Category"
                    onChange={(e) => {

                        var findCategory = categories.find((y) => y.id === e.target.value as any);
                        var subCategory = categories.find((y) => {
                            if (y.categories != null) {
                                return y.categories.find((x) => x.id === e.target.value as any);
                            }
                            return null;
                        });
                        if (findCategory != null) {
                            handleProductFormChange("categoryID", findCategory.id);
                            setSelectedCategory(e.target.value as any);
                        }
                        else {
                            handleProductFormChange("categoryID", subCategory?.id);
                            setSelectedCategory(e.target.value as any);
                        }

                    }}
                >
                    <MenuItem value={0}>{"Select"}</MenuItem>
                    {
                        categories.map((category, index) => {
                            if (category.categories != null && category.categories.length !== 0) {
                                return category.categories.map((item, index) => {
                                    return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                })
                            }
                            else {
                                return <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                            }

                        })
                    }
                </Select>
            </FormControl>
            {productForm.productType === ProductType.Simple && <Grid container columnSpacing={2}>
                <Grid item sm={6} md={6} xs={6}>
                    <TextField
                        value={productForm.stock_Quantity}
                        onChange={(e) => handleProductFormChange("stock_Quantity", e.target.value)}
                        margin="dense"
                        required
                        fullWidth
                        id="stockquantity"
                        label="Stock Quantity"
                        name="stockquantity"
                    />
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                    <TextField
                        value={productForm.sales_Quantity}
                        onChange={(e) => handleProductFormChange("sales_Quantity", e.target.value)}
                        margin="dense"
                        required
                        fullWidth
                        id="salesquantity"
                        label="Sales Quantity"
                        name="salesquantity"
                    />
                </Grid>
            </Grid>}
            <Grid container columnSpacing={2}>
                <Grid item sm={6} md={6} xs={6}>
                    <TextField
                        value={productForm.price}
                        onChange={(e) => handleProductFormChange("price", e.target.value)}
                        margin="dense"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                    />
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                    <TextField
                        value={productForm.discount_Rate}
                        onChange={(e) => handleProductFormChange("discount_Rate", e.target.value)}
                        margin="dense"
                        required
                        fullWidth
                        id="discount_Rate"
                        label="Discount Rate"
                        name="discount_Rate"
                    />
                </Grid>
            </Grid>
            <FormControl margin='dense' fullWidth>
                <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={productForm.productType}
                    label="Product Type"
                    onChange={(e) => {
                        handleProductFormChange("productType", e.target.value as any);
                    }}
                >
                    <MenuItem value={ProductType.Simple}>Basic</MenuItem>
                    <MenuItem value={ProductType.Variant}>Variant</MenuItem>
                </Select>
            </FormControl>
            <Grid container>
                <VerticalTabs

                    variants={variants}
                    productType={productForm.productType}
                    handleSelectVariant={(entity) => setSelectedVariants(entity)}
                    selectedVariants={selectedVariants} />
            </Grid>
            <Grid>
                <Button
                    onClick={addProduct}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save
                </Button>
            </Grid>
        </Box>
    )
}



