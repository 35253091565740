import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setDeleteOpenDialog, setHandleDelete } from '../store/features/helperReducers';

export default function DeleteDialog() {
    console.log("a");
    const {
        deleteOpenDialog,
        deleteButtons,
        deleteMessage,
        deleteTitle,
        handleDelete
    } = useSelector((state: RootState) => state.helperReducers);
    const dispatch = useDispatch();
    return (
        <div>
            <Dialog
                open={deleteOpenDialog}
                onClose={() => dispatch(setDeleteOpenDialog(false))}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {deleteTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            deleteMessage
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch(setDeleteOpenDialog(false))}>{deleteButtons[0]}</Button>
                    <Button onClick={handleDelete} autoFocus>
                        {deleteButtons[1]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}