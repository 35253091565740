import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading';
import DataTable, { EnhancedTableToolbarProps } from '../components/DataTable';


import { getComparator, Order, stableSort } from '../components/TableHelper';
import { OrderVM } from '../types/Entites';
import { deleteOrders, getOrders } from '../utils/api'
import { alpha, Checkbox, Grid, IconButton, Paper, TableBody, TableCell, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { OrderVmTableHeadCells } from '../utils/TableHeadCells';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setOrders, setSelectedOrder } from '../store/features/orderReducers';
import DeleteDialog from '../components/DeleteDialog';
import { setDeleteOpenDialog, setHandleDelete } from '../store/features/helperReducers';
export default function Orders() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { orders } = useSelector((x: RootState) => x.orderReducers);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof OrderVM>('id');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const deleteDialog = useSelector((x: RootState) => x.helperReducers.deleteOpenDialog);





    const navigate = useNavigate();


    useEffect(() => {
        loadOrders();
        return () => {
            setLoading(true);
        }
    }, [])

    const loadOrders = async () => {
        await getOrders(page + 1, rowsPerPage).then((res) => {
            dispatch(setOrders(res.data.list));
        }).catch((er) => {
            console.log(er)
        });
        setLoading(false);
    }
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const { numSelected } = props;
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {props.tableName}
                    </Typography>
                )}
                {numSelected == 1 &&
                    <Tooltip title="Düzenle">
                        <IconButton onClick={props.goEditPage}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }
                {
                    numSelected > 0 &&
                    <Tooltip title="Sil">
                        <IconButton onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar >
        );
    };
    const handleDeleteOrders = async () => {
        await deleteOrders(selected.map((item) => parseInt(item))).then((res) => {
            window.location.reload();
        }).catch((er) => {
            console.log(er);
        });
        dispatch(setDeleteOpenDialog(false));
    }
    return (
        <Loading loading={loading}>
            <Grid container sx={{ padding: "10px" }}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        {orders !== undefined && <DataTable
                            EnhancedTableToolbar={EnhancedTableToolbar}
                            rows={orders.map((item) => item.id.toString())}
                            HeadCell={OrderVmTableHeadCells}
                            order={order}
                            setOrder={(data) => setOrder(data)}
                            orderBy={orderBy}
                            setOrderBy={(data) => setOrderBy(data)}
                            selected={selected}
                            setSelected={(data) => setSelected(data)}
                            page={page}
                            setPage={(page) => setPage(page)}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={(data) => setRowsPerPage(data)}
                            goAddPage={() => {

                            }}
                            goEditPage={() => {
                                dispatch(setSelectedOrder(orders.find(x => x.id.toString() == selected[0])));
                                navigate('/order/' + selected[0]);
                            }}
                            handleDelete={() => {
                                dispatch(setHandleDelete(handleDeleteOrders));
                                dispatch(setDeleteOpenDialog(true));

                            }}
                            tableName="Orders"
                            tableBody={orders !== undefined && orders.length !== 0 &&
                                <TableBody>
                                    {stableSort(orders, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id.toString());
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id.toString())}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{row.id}</TableCell>
                                                    <TableCell>{row.customer.firstName + " " + row.customer.lastName}</TableCell>
                                                    <TableCell>{row.customer.email}</TableCell>
                                                    <TableCell>{new Intl.NumberFormat("de-De", { style: 'currency', currency: 'EUR' }).format(row.orderBasketVMs.reduce((a, b) => a = a + b.price * b.quantity, 0))}</TableCell>
                                                    <TableCell>{row.orderStatus.name}</TableCell>
                                                    <TableCell>{new Date(row.dateCreate).toLocaleString()}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            }
                        />}
                    </Paper>
                </Grid>
            </Grid>
            {deleteDialog && <DeleteDialog />}
        </Loading>
    )
}
