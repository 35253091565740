import { createSlice } from "@reduxjs/toolkit";
import { GroupedProduct, Product, ProductVariant, ProductVariantForm, Variant, VariantItem } from "../../types/Entites";

const INITIAL_STATE = {
    variants: [] as Array<Variant>,
    variantItems: [] as Array<VariantItem>,
    variantForm: { name: '', description: '' } as Variant,
    variantItemForm: { name: '', value: '' } as VariantItem,
    selectedProductVariants: [] as Array<ProductVariantForm>,
    selectedVariantItems: [] as Array<VariantItem>
}
const variantSlice = createSlice({
    name: 'variant',
    initialState: INITIAL_STATE,
    reducers: {
        setVariants: (state, action) => {
            state.variants = action.payload;
        },
        setVariantItems: (state, action) => {
            state.variantItems = action.payload;
        },
        setVariant: (state, action) => {
            state.variants = [...state.variants, action.payload];
        },
        setVariantItem: (state, action) => {
            state.variantItems = [...state.variantItems, action.payload];
        },
        setVariantForm: (state, action) => {
            state.variantForm = action.payload;
        },
        setVariantItemForm: (state, action) => {
            state.variantItemForm = action.payload;
        },
        setSelectedProductVariants: (state, action) => {
            state.selectedProductVariants = action.payload;
        },
        setSelectedProductVariant: (state, action) => {
            state.selectedProductVariants = [...state.selectedProductVariants, action.payload];
        },
        updateSelectedProductVariant: (state, action) => {
            state.selectedProductVariants = state.selectedProductVariants.map((item) => item.key == action.payload.key ? action.payload : item);
        },
        setSelectedVariantItems: (state, action) => {
            state.selectedVariantItems = action.payload;
        },
        setSelectedVariantItem: (state, action) => {
            state.selectedVariantItems = [...state.selectedVariantItems, action.payload];
        }
    }
});
export const variantReducers = variantSlice.reducer;
export const {
    setVariants,
    setVariantItems,
    setVariant,
    setVariantItem,
    setVariantForm,
    setVariantItemForm,
    setSelectedProductVariants,
    updateSelectedProductVariant,
    setSelectedProductVariant,
    setSelectedVariantItems,
    setSelectedVariantItem
} = variantSlice.actions;