import { HeadCell } from "../components/TableHelper";
import { Brands, Category, OrderVM, ProductDTO, Variant, VariantItem } from "../types/Entites";

export const CategoryTableHeadCells: HeadCell<Category>[] = [
    {
        id: 'image' as any,
        disablePadding: false,
        label: 'Image',
        align: 'left'
    },
    {
        id: 'name' as any,
        disablePadding: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'description' as any,
        disablePadding: false,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'parent' as any,
        disablePadding: false,
        label: 'Parent',
        align: 'left'
    },
    {
        id: 'status' as any,
        disablePadding: true,
        label: 'Status',
        align: 'left'
    },
    {
        id: 'categoryType' as any,
        disablePadding: false,
        label: 'Category Type',
        align: 'left'
    }
];
export const VariantTableHeadCells: HeadCell<Variant>[] = [
    {
        id: 'name' as any,
        disablePadding: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'Description' as any,
        disablePadding: false,
        label: 'Description',
        align: 'left'
    }
];
export const VariantItemTableHeadCells: HeadCell<VariantItem>[] = [
    {
        id: 'name' as any,
        disablePadding: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'value' as any,
        disablePadding: false,
        label: 'Value',
        align: 'left'
    },
];
export const BrandsTableHeadCells: HeadCell<Brands>[] = [
    {
        id: 'image' as any,
        disablePadding: false,
        label: 'Image',
        align: 'left'
    },
    {
        id: 'name' as any,
        disablePadding: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'description' as any,
        disablePadding: false,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'status' as any,
        disablePadding: false,
        label: 'Status',
        align: 'left'
    }
];
export const ProductTableHeadCells: HeadCell<ProductDTO>[] = [
    {
        id: 'image' as any,
        disablePadding: false,
        label: 'Image',
        align: 'left'
    },
    {
        id: 'name' as any,
        disablePadding: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'description' as any,
        disablePadding: false,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'categoryID' as any,
        disablePadding: false,
        label: 'Category',
        align: 'left'
    },
    {
        id: 'brandID' as any,
        disablePadding: false,
        label: 'Brand',
        align: 'left'
    },
    {
        id: 'productType' as any,
        disablePadding: false,
        label: 'Product Type',
        align: 'left'
    },
    {
        id: 'productStatus' as any,
        disablePadding: false,
        label: 'Product Status',
        align: 'left'
    },
    {
        id: 'discount_Rate' as any,
        disablePadding: false,
        label: 'Discount Rate',
        align: 'left'
    },
    {
        id: 'stock_Quantity' as any,
        disablePadding: false,
        label: 'Stock Quantity',
        align: 'left'
    },
    {
        id: 'sales_Quantity' as any,
        disablePadding: false,
        label: 'Sales Quantity',
        align: 'left'
    },
    {
        id: 'price' as any,
        disablePadding: false,
        label: 'Price',
        align: 'left'
    },
    {
        id: 'discount_Price' as any,
        disablePadding: false,
        label: 'Discount Price',
        align: 'left'
    },
    {
        id: 'createdAt' as any,
        disablePadding: false,
        label: 'Created At',
        align: 'left'
    },
]
export const OrderVmTableHeadCells: HeadCell<OrderVM>[] = [
    {
        id: 'id' as any,
        disablePadding: false,
        label: 'ID',
        align: 'left'
    },
    {
        id: 'customerName' as any,
        disablePadding: false,
        label: 'Customer Name',
        align: 'left'
    },
    {
        id: 'customerEmail' as any,
        disablePadding: false,
        label: 'Customer Email',
        align: 'left'
    },
    {
        id: 'price' as any,
        disablePadding: false,
        label: 'Price',
        align: 'left'
    },
    {
        id: 'status' as any,
        disablePadding: false,
        label: 'Status',
        align: 'left'
    },
    {
        id: 'createdAt' as any,
        disablePadding: false,
        label: 'Created At',
        align: 'left'
    },
];