import React from 'react'
import { Route, Routes } from 'react-router'
import { ProtectedLayout } from './components/ProtectedLayout'
import Brands from './pages/Brands'
import AddBrand from './pages/Brand/AddBrand'
import EditBrand from './pages/Brand/EditBrand'
import Categories from './pages/Categories'
import AddCategory from './pages/Category/AddCategory'
import EditCategory from './pages/Category/EditCategory'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Products from './pages/Products'
import Variants from './pages/Variants'
import AddVariant from './pages/Variant/AddVariant'
import EditVariant from './pages/Variant/EditVariant'
import AddProduct from './pages/Product/AddProduct'
import EditProduct from './pages/Product/EditProduct'
import Orders from './pages/Orders'
import EditOrder from './pages/Order/EditOrder'
import Register from './pages/Register'


export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />}></Route>
      <Route path='/register' element={<Register />}></Route>
      <Route element={<ProtectedLayout />}>
        <Route path='/orders' element={<Orders />}></Route>
        <Route path='/order/:id' element={<EditOrder />}></Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/categories' element={<Categories />}></Route>
        <Route path='/category/add' element={<AddCategory />}></Route>
        <Route path='/category/:id' element={<EditCategory />}></Route>
        <Route path='/products' element={<Products />}></Route>
        <Route path='/product/add' element={<AddProduct />}></Route>
        <Route path='/product/:id' element={<EditProduct />}></Route>
        <Route path='/brands' element={<Brands />}></Route>
        <Route path='/brand/add' element={<AddBrand />}></Route>
        <Route path='/brand/:id' element={<EditBrand />}></Route>
        <Route path='/variants' element={<Variants />}></Route>
        <Route path='/variant/add' element={<AddVariant />}></Route>
        <Route path='/variant/:id' element={<EditVariant />}></Route>


      </Route>
    </Routes>
  )
}
