import { AccountBox, Add, AddBox, BrandingWatermark, Category, Dashboard, IndeterminateCheckBoxRounded, Inventory, List, Menu as MenuIcon, ProductionQuantityLimits, ShoppingBag, Widgets } from "@mui/icons-material";
export const NavItems = [
    {
        name: 'Dashboard',
        icon: <Dashboard />,
        path: '/dashboard',
        auth: 'Customer',
        children: []
    },
    {
        name: 'Orders',
        icon: <ShoppingBag />,
        path: '/orders',
        auth: 'Admin',
        children: []
    },
    {
        name: 'Categories',
        icon: <Widgets />,
        path: '/categories',
        auth: 'Admin',
        children: [
            {
                name: 'Category List',
                path: '/categories',
                icon: <List />,
            },
            {
                name: 'Add Category',
                path: '/category/add',
                icon: <Add />,
            },
        ]
    },
    {
        name: 'Products',
        icon: <Inventory />,
        path: '/Products',
        auth: 'Admin',
        children: [
            {
                name: 'Product List',
                path: '/products',
                icon: <List />,
            },
            {
                name: 'Add Product',
                path: '/product/add',
                icon: <Add />,
            },
        ]
    },
    {
        name: 'Brands',
        icon: <BrandingWatermark />,
        path: '/brands',
        auth: 'Admin',
        children: [
            {
                name: 'Brand List',
                path: '/brands',
                icon: <List />,
            },
            {
                name: 'Add Brand',
                path: '/brand/add',
                icon: <Add />,
            },
        ]
    },
    {
        name: 'Variants',
        icon: <Category />,
        path: '/variants',
        auth: 'Admin',
        children: [
            {
                name: 'Variant List',
                path: '/variants',
                icon: <List />,
            },
            {
                name: 'Add Variant',
                path: '/variant/add',
                icon: <Add />,
            },
        ]
    }
];